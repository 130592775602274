import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AddButton,
  AddLineButton,
  ClearButton,
  ListButton,
  SaveButton,
} from "../../../Components/Utils/StyledButtons";
import { useTranslation } from "react-i18next";
import { BarcodeTextBox } from "../../../Components/Utils/BarcodeTextBox";
import NotesTextArea from "../../../Components/Utils/TextArea";
import VBInputField from "../../../Components/Utils/VBInputField";
import VBDatePiker from "../../../Components/Utils/VBDatePiker";
import moment from "moment";
import ViknAutoComplete from "../../../Components/Utils/ViknAutoComplete";
import { blue } from "@mui/material/colors";
import MyGrid from "./Details2";
import { useDispatch, useSelector } from "react-redux";
import {
  callProductGroupAPI,
  callStockTransferFilterAPI,
  callStockTransferPageDetailsAPI,
} from "../../../Api/Inventory/StockTransferAPI";
import { SalesInvoiceCalc, SalesLineCalc } from "./function";
import { openSnackbar } from "../../../features/SnackBarslice";
import {
  get_pricelist_list_new_api,
  get_single_pricelist_new_api,
  product_search_new_api,
} from "../../../Api/CommonApi/CommonApis";
import { debounce } from "../../../Function/comonFunction";
import { Group } from "@mui/icons-material";
function CreateStockTransfer() {
  // ======================== Imports =====================

  // redux
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // language
  const [t] = useTranslation("common");

  const { PriceDecimalPoint, EnableEinvoiceKSA, EnableProductBatchWise } =
    useSelector((state) => state.generalSettingsSclice.generalSettings);

  // ===================== variables =====================

  let dataListItem = {
    ProductCode: null,
    BarCode: "",
    Product: null,
    Unit: null,
    Qty: "",
    Rate: "",
    Amount: "",
  };

  let GeneralSettingsData = {
    PriceDecimalPoint,
    EnableEinvoiceKSA,
  };

  // const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  // ======================== States ======================

  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    WarehouseResponse: null,
    ProductGroupResponse: null,
    ProductList: [],
  });
  
  const [state, setState] = useState({
    fromWareHouse: null,
    toWareHouse: null,
    productGroup: null,
    allProductOrProductGroup: "all-product",
    date: moment(),
    fromStockOrderNo: "",
    barcode: null,
    totalQuantity: 0,
    totalAmount: 0,
    notes: " ",
  });

  const [dataList, setDataLists] = useState([dataListItem]);

  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: false,
    Product: true,
    Unit: true,
    Qty: true,
    Rate: true,
    Amount: true,
    Activity: true,
  });

  const [focusSettings, setFocusSettings] = useState({
    ProductCode: true,
    Product: true,
  });

  const [isManualRoundOff, setIsManualRoundOff] = useState(false);

  // ====================== functions =====================

  const fetchData = async () => {
    const stockTransferPageDetailsAPiResponse =
      await callStockTransferPageDetailsAPI({
        CompanyID: CompanyID,
        CreatedUserID: user_id,
        BranchID: BranchID,
      });

    if (stockTransferPageDetailsAPiResponse.StatusCode === 6000) {
      setDataState((prevState) => ({
        ...prevState,
        Seperator: stockTransferPageDetailsAPiResponse.data.voucher.Seperator,
        Suffix: stockTransferPageDetailsAPiResponse.data.voucher.Suffix,
        SuffixSeparator:
          stockTransferPageDetailsAPiResponse.data.voucher.SuffixSeparator,
        PreFix: stockTransferPageDetailsAPiResponse.data.voucher.PreFix,
        InvoiceNo: stockTransferPageDetailsAPiResponse.data.voucher.InvoiceNo,
        VoucherNo: stockTransferPageDetailsAPiResponse.data.voucher.VoucherNo,
        WarehouseResponse:
          stockTransferPageDetailsAPiResponse.data.Warehouse.data,
      }));
      setState((prevState) => ({
        ...prevState,
        fromWareHouse:
          stockTransferPageDetailsAPiResponse.data.Warehouse.data.find(
            (item) => item.IsDefaultWarehouse
          ),
        toWareHouse:
          stockTransferPageDetailsAPiResponse.data.Warehouse.data.find(
            (item) => !item.IsDefaultWarehouse[0]
          ),
      }));
    }

    const ProductGroupAPiResponse = await await callProductGroupAPI({
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      BranchID: BranchID,
    });
    if (ProductGroupAPiResponse.StatusCode === 6000) {
      setDataState((prevState) => ({
        ...prevState,
        ProductGroupResponse: ProductGroupAPiResponse.data,
      }));
    }
  };

  function customRound(x) {
    if (EnableEinvoiceKSA) {
      const rounded = Math.round(x * 100) / 100;

      if (rounded - x === 0.5) {
        return rounded;
      }

      return rounded;
    } else {
      return Number(x);
    }
  }

  const RemoveLine = (index) => {
    let Data = dataList;
    let deletedData = [];
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        // if (is_edit) {
        //   deletedData.push(Data[index]);
        // }
        deletedData.push(Data[index]);

        Data.splice(index, 1);
      }
    }

    let newState = SalesInvoiceCalc(
      dataState,
      state,
      Data,
      isManualRoundOff,
      customRound
    );
    setState({ ...newState, deletedData: deletedData });

    setDataLists([...Data]);
  };

  const AddLine = () => {
    let Data = dataList;
    let newData = dataListItem;
    Data.push(newData);
    setDataLists([...Data]);
  };

  const [errors, setErrors] = useState({
    field: null,
    message: null,
    error: false,
    indexList: [],
  });

  // Product Search API
  const Call_ProductSearchFunction = async (payload) => {
    let data = [];
    const Response = await product_search_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ? Response.message : "API error",
          severity: "error",
        })
      );
    }
    return data;
  };

  const onDetailsInputChange = async (e, name) => {
    console.log("sssssssssssssssss");

    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.fromWareHouse.WarehouseID,
          is_product_image: false,
          length: value?.length,
          product_name: value,
          // type: "SalesReturn",
        });
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };

  const onDetailsChange = async (e, index) => {
    if (e) {
      let value = e.target.value;
      let name = e.target.name;
      let Data = dataList;
      let lineItem = Data[index];
      lineItem[name] = value;

      // handling Line Discount
      let Amount = lineItem.GrossAmount;
      if (name === "DiscountAmount") {
        lineItem.DiscountPerc = 100 * (Number(value) / Number(Amount));
      } else if (name === "DiscountPerc") {
        lineItem.DiscountAmount = (Number(value) / 100) * Number(Amount);
      }

      let calculated_lineItem = await SalesLineCalc(
        dataState,
        state,
        lineItem,
        customRound
      );

      Data[index] = calculated_lineItem;
      setDataLists([...dataList]);
    }
  };

  const ChangeTableCustomization = (type, name) => {
    let value = null;
    if (type === "tableSettings") {
      value = !tableSettings[name];
      setTableSettings({
        ...tableSettings,
        [name]: value,
      });
    } else {
      value = !focusSettings[name];
      setFocusSettings({
        ...focusSettings,
        [name]: value,
      });
    }
  };

  const clearStates = () =>
    setState((prevState) => ({
      ...prevState,
      fromWareHouse: dataState.WarehouseResponse.find(
        (item) => item.IsDefaultWarehouse
      ),
      toWareHouse: dataState.WarehouseResponse.find(
        (item) => !item.IsDefaultWarehouse
      ),
      productGroup: null,
      allProductOrProductGroup: "all-product",
      date: moment(),
      fromStockOrderNo: "",
      barcode: null,
      totalQuantity: 0,
      totalAmount: 0,
      notes: " ",
    }));

  const Call_PriceList_list_Function = async (payload) => {
    let data = [];
    const Response = await get_pricelist_list_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const Call_Single_PriceList_Function = async (payload) => {
    let data = null;
    const Response = await get_single_pricelist_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const onDetailsAutoChange = async (v, index, name) => {
    console.log(v, name, "333333333");
    if (v && name && v?.id) {
      let Data = dataList;
      let lineItem = Data[index];

      if (name === "Product" || name === "ProductCode") {
        let Product = v;
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id: Product.id,
          type_data: "SI", //______________----------------------------------------------------------------------------------------------------------------------------
        });

        let Unit = UnitList.find((i) => i.DefaultUnit === true);
        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.fromWareHouse.WarehouseID,
        });

        let Rate = 0;
        let Qty = 1;
        let FQty = 0;
        let DiscountPerc = 0;
        let DiscountAmount = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.SalesPrice);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }

        if (EnableProductBatchWise === true) {
          if (UnitDetails.BatchCode) {
            lineItem.Rate = UnitDetails.BatchCode.SalesPrice;
            lineItem.PurchasePrice = UnitDetails.BatchCode.PurchasePrice;
            lineItem.Stock = lineItem.BatchCode.Stock;
          }
        }

        // let Tax = dataState.TaxCategoryData.find(
        //   (i) => i.TaxID === Product.ProductTaxID
        // );

        lineItem.Product = Product;
        lineItem.ProductCode = Product;
        lineItem.Stock = Product.Stock;
        lineItem.Qty = Qty;
        lineItem.DiscountPerc = DiscountPerc;
        lineItem.DiscountAmount = DiscountAmount;

        lineItem.Unit = Unit;
        lineItem.UnitList = UnitList;

        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      } else if (name === "Unit") {
        let Unit = v;

        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
        });
        let Rate = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.SalesPrice);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }
        lineItem.Unit = Unit;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;
        setDataLists([...Data]);
      } else if (name === "Tax") {
        let Tax = v;
        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      } else if (name === "BatchCode") {
        let BatchCode = v;
        lineItem.BatchCode = BatchCode;
        lineItem.Rate = BatchCode.SalesPrice;
        lineItem.PurchasePrice = BatchCode.PurchasePrice;
        lineItem.Stock = BatchCode.Stock;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      }
    }
  };

  const handleProductAddButtonClick = async () => {
    const stockTransferListAPiResponse = await callStockTransferFilterAPI({
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      BranchID: BranchID,
      PriceRounding: 2,
      filterValue: state.allProductOrProductGroup === "product-group" ? 1 : 2,
      GroupID: state.productGroup ? state.productGroup.ProductGroupID : null,
      page_no: 1,
      items_per_page: 20,
      WarehouseFromID: state.fromWareHouse.WarehouseID,
    });
    if (stockTransferListAPiResponse.StatusCode === 6000) {
      setDataState((prevState) => ({
        ...prevState,
        ProductList: stockTransferListAPiResponse.data,
      }));
    }
  };

  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* --------- Top container ---------*/}

      <Paper sx={{ height: "100%", width: "100%", overflow: "scroll" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "500",
              cmr: 2,
              color: "#001746",
            }}
            variant="h2"
          >
            {t("Create Stock Transfer")}
          </Typography>
          <Box>
            <ClearButton t={t} onClick={clearStates} />
            <ListButton t={t} />
            <SaveButton t={t} />
          </Box>
        </Box>

        {/* -------- Filter Section ----------- */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ---------- column one ------------- */}

          <Grid container spacing={2}>
            <Grid
              item
              xs={1.5}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <VBInputField
                placeholder={t("Voucher No")}
                value={dataState.VoucherNo}
              />
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <VBInputField
                sx={{ width: "200px" }}
                placeholder={t("From Stock Order No")}
                value={state.fromStockOrderNo}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    fromStockOrderNo: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <Typography>{t("Date")}</Typography>
              <VBDatePiker
                fromDate={state.date}
                fromOnChange={(e) =>
                  setState((prevState) => ({ ...prevState, date: e }))
                }
                label={true}
              />
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <Typography>{t("From")}</Typography>
              <ViknAutoComplete
                placeholder={t("Warehouse")}
                options={dataState.WarehouseResponse}
                getOptionLabel={(option) => option.WarehouseName}
                isOptionEqualToValue={(option, value) =>
                  option.name === value?.name
                }
                onChange={(event, newValue) => {
                  setState((prevState) => ({
                    ...prevState,
                    fromWareHouse: newValue,
                    toWareHouse: newValue?.IsDefaultWarehouse
                      ? dataState.WarehouseResponse.find(
                          (item) => item.id !== newValue?.id
                        )
                      : dataState.WarehouseResponse.find(
                          (item) => item.IsDefaultWarehouse
                        ),
                  }));
                }}
                value={state.fromWareHouse}
              />
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <Typography>{t("To")}</Typography>
              <ViknAutoComplete
                placeholder={t("Warehouse")}
                options={dataState.WarehouseResponse}
                getOptionLabel={(option) => option.WarehouseName}
                isOptionEqualToValue={(option, value) =>
                  option.name === value?.name
                }
                onChange={(event, newValue) =>
                  setState((prevState) => ({
                    ...prevState,
                    toWareHouse: newValue,
                    fromWareHouse:
                      newValue?.id === state.fromWareHouse?.id
                        ? dataState.WarehouseResponse.find(
                            (item) => item.id !== newValue?.id
                          )
                        : state.fromWareHouse,
                  }))
                }
                value={state.toWareHouse}
              />
            </Grid>
            <Grid
              item
              xs={2.2}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                }
                label={t("All Product")}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    allProductOrProductGroup: "all-product",
                  }))
                }
                checked={state.allProductOrProductGroup === "all-product"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                }
                label={"Product Group"}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    allProductOrProductGroup: "product-group",
                  }))
                }
                checked={state.allProductOrProductGroup === "product-group"}
              />
            </Grid>
            <Grid
              item
              xs={2.3}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              {state.allProductOrProductGroup === "product-group" && (
                <ViknAutoComplete
                  placeholder={t("Product Group")}
                  sx={{ width: "200px" }}
                  options={dataState.ProductGroupResponse}
                  getOptionLabel={(option) => option.GroupName}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value?.name
                  }
                  onChange={(event, newValue) =>
                    setState((prevState) => ({
                      ...prevState,
                      productGroup: newValue,
                    }))
                  }
                  value={state.productGroup}
                />
              )}
              <AddButton t={t} onClick={handleProductAddButtonClick} />
            </Grid>
          </Grid>
        </Box>
        <MyGrid
          dataState={dataState}
          state={state}
          DataList={dataList}
          setDataLists={setDataLists}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          focusSettings={focusSettings}
          setFocusSettings={setFocusSettings}
          handleInputChange={onDetailsInputChange}
          handleCompleteChanges={onDetailsAutoChange}
          handleChanges={onDetailsChange}
          AddLine={AddLine}
          RemoveLine={RemoveLine}
          PriceDecimalPoint={PriceDecimalPoint}
          Errors={errors}
          debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
          ChangeTableCustomization={ChangeTableCustomization}
          GeneralSettingsData={GeneralSettingsData}
        />
        {/* ---------- Footer Section ---------*/}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <AddLineButton t={t} onClick={AddLine} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ width: "250px" }}>
              {t("Add By Barcode")}
            </Typography>
            <BarcodeTextBox
              handleChanges={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  barcode: e.target.value,
                }))
              }
              handleKeyDown={(e) => {}}
            />
          </Box>
        </Box>

        {/* ---------- Total Section ---------- */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ width: "300px" }}>
            <NotesTextArea
              placeholder={t("Notes (Optional)")}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  notes: e.target.value,
                }))
              }
            />
          </Box>

          {/* Price box */}

          <Table
            sx={{ width: "500px", border: "none" }}
            aria-label="simple table"
          >
            <TableBody>
              <TableRow
                sx={{
                  "& td, & th": { border: "none", backgroundColor: "#F9F9F9" },
                }}
              >
                <TableCell component="th" scope="row">
                  {t("Total Quantity")}
                </TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">{state.totalQuantity}</TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "& td, & th": { border: "none", backgroundColor: "#F9F9F9" },
                }}
              >
                <TableCell component="th" scope="row">
                  {t("Total Amount")}
                </TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">{state.totalAmount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </>
  );
}

export default CreateStockTransfer;
