import { Collapse, IconButton, InputBase, Paper, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

function SearchTextField({ value, onChange, onEnterDown, escClearState, isCloseEnter }) {
  const [checked, setChecked] = useState(false);
  const inputRef = useRef(null);

  const handleClick = function () {
    setChecked((prev) => !prev);
    if (!checked) {
      setTimeout(() => {
        inputRef.current?.focus(); // Focus the input field when opening
      }, 300); // Delay to allow Collapse animation. Always put transaction duration to the time
    }
  };

  const handleKeyDown = function (event) {
    // console.log(event.key, event.keyCode);

    if (event.key === "Enter" && event.keyCode === 13) {
      onEnterDown();
      isCloseEnter && setChecked(false);
    }

    if (event.key === "Escape" && event.keyCode === 27) {
      setChecked(false);
      escClearState("");
    }
  };

  return (
    <Paper
      elevation={0}
      rou
      sx={{
        // boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.2)",
        backgroundColor: "background.default",
        display: "flex",
        alignItems: "center",
        borderRadius: 6,
      }}
    >
      <IconButton onClick={handleClick} color="secondary">
        <SearchIcon />
      </IconButton>
      <Collapse orientation="horizontal" in={checked}>
        <InputField
          inputRef={inputRef}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
      </Collapse>
    </Paper>
  );
}

SearchTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEnterDown: PropTypes.func,
  escClearState: PropTypes.func,
  isCloseEnter: PropTypes.bool
};

SearchTextField.defaultProps = {
  value: "",
  onChange: null,
  isCloseEnter: false,
  onEnterDown: () => {},
  escClearState: () => {},
};

export default SearchTextField;

const InputField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  //   width: "100%",
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "none",
    fontSize: 14,
    fontWeight: "500",
    // width: "100%",
    padding: "6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    //   border: "1px solid",
    // },
  },
  "&.MuiInputBase-root": {
    // marginTop: "3px",
  },
}));
