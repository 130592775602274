import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Icons } from "../../Assets/AssetLog";
import { useTranslation } from "react-i18next";
import {
  GetEditionPermission,
  UserRolePermission,
} from "../../Function/Editions";
import { Report_URL, VIKNBOOKS_FRONT_URL } from "../../generalVeriable";
import { useSelector } from "react-redux";

const SideNavigation = () => {
  const [t] = useTranslation("common");
  const ref = useRef(null);
  const [sideMenuHeight, setsideMenuHeight] = useState(0);
  const { Edition, BusinessTypeName } = useSelector(
    (state) => state.companyDetails
  );
  const { EnableEinvoiceKSA } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const {
    GST,
    VAT,
    EnableProductBatchWise,
    EnableLoyaltySettings,
    EnableWarehouse,
    EnableRoute,
    EnableBranch,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  // sales permissions
  let sales_view = UserRolePermission("Sales Invoice", "view_permission");
  let salesReturn_view = UserRolePermission("Sales Return", "view_permission");
  let salesOrder_view = UserRolePermission("Sales Order", "view_permission");
  let salesEstimate_view = UserRolePermission(
    "Sales Estimate",
    "view_permission"
  );
  // ---------------------------------------------------------
  // purchase permissions
  let purchase_view = UserRolePermission("Purchase Invoice", "view_permission");
  let purchaseReturn_view = UserRolePermission(
    "Purchase Return",
    "view_permission"
  );
  let purchaseOrder_view = UserRolePermission(
    "Purchase Order",
    "view_permission"
  );
  let purchaseEstimate_view = UserRolePermission(
    "Purchase Estimate",
    "view_permission"
  );
  let payment_view = UserRolePermission("Payment Voucher", "view_permission");
  let receipt_view = UserRolePermission("Receipt Voucher", "view_permission");
  let customer_view = UserRolePermission("Customer", "view_permission");
  let supplier_view = UserRolePermission("Supplier", "view_permission");
  let employees_view = UserRolePermission("Employee", "view_permission");
  let product_view = UserRolePermission("Product", "view_permission");
  let product_save = UserRolePermission("Product", "save_permission");
  let brands_view = UserRolePermission("Brands", "view_permission");
  let productGroup_view = UserRolePermission(
    "Product Groups",
    "view_permission"
  );
  let productCategory_view = UserRolePermission(
    "Product Category",
    "view_permission"
  );
  // ---------------------------------------------------------
  // Report permissions
  let trialBalance_view = UserRolePermission(
    "Trial Balance",
    "view_permission"
  );
  let profitAndLoss_view = UserRolePermission(
    "Profit And Loss Account",
    "view_permission"
  );
  let balanceSheet_view = UserRolePermission(
    "Balance Sheet",
    "view_permission"
  );
  let outStandingReport_view = UserRolePermission(
    "Outstanding Report",
    "view_permission"
  );
  let dailyReport_view = UserRolePermission("Dily Report", "view_permission");
  let cashBook_view = UserRolePermission("CashBook", "view_permission");
  let bankBook_view = UserRolePermission("Bank Book", "view_permission");
  let receiptReport_view = UserRolePermission(
    "Receipt Report",
    "view_permission"
  );
  let paymentReport_view = UserRolePermission(
    "Payment Report",
    "view_permission"
  );
  let ledgerReport_view = UserRolePermission(
    "Ledger Report",
    "view_permission"
  );
  let billWiseReportReport_view = UserRolePermission(
    "Bill Wise Profit Report",
    "view_permission"
  );
  let salesReport_view = UserRolePermission("Sales Report", "view_permission");
  let salesSummaryReport_view = UserRolePermission(
    "Sales Summary Report",
    "view_permission"
  );
  let salesRegisterReport_view = UserRolePermission(
    "Sales Register Report",
    "view_permission"
  );
  let salesReturnRegister_view = UserRolePermission(
    "Sales Return Register Report",
    "view_permission"
  );
  let salesIntegratedReport_view = UserRolePermission(
    "Sales Integrated Report",
    "view_permission"
  );
  let purchaseReport_view = UserRolePermission(
    "Purchase Report",
    "view_permission"
  );
  let purchaseOrderReport_view = UserRolePermission(
    "Purchase Order Report",
    "view_permission"
  );
  let purchaseRegisterReport_view = UserRolePermission(
    "Purchase Register Report",
    "view_permission"
  );
  let purchaseReturnRegister_view = UserRolePermission(
    "Purchase Return Register Report",
    "view_permission"
  );
  let supplierVsProduct_view = UserRolePermission(
    "Supplier Vs Product Report",
    "view_permission"
  );
  let stockReport_view = UserRolePermission("Stock Report", "view_permission");
  let stockValueReport_view = UserRolePermission(
    "Stock Value Report",
    "view_permission"
  );
  let stockLedgerReport_view = UserRolePermission(
    "Stock Ledger Report",
    "view_permission"
  );
  let productReport_view = UserRolePermission(
    "Product Report",
    "view_permission"
  );
  let productAnalysisReport_view = UserRolePermission(
    "Product Analysis Report",
    "view_permission"
  );
  // let inventoryFlow_view = UserRolePermission(
  //   "Inventory Flow Report",
  //   "view_permission"
  // );
  let batchWiseReport_view = UserRolePermission(
    "Batch Wise Report",
    "view_permission"
  );
  let excessStockReport_view = UserRolePermission(
    "Excess Stock Report",
    "view_permission"
  );
  let shortageStock_view = UserRolePermission(
    "Shortage Stock Report",
    "view_permission"
  );
  let openingStockReport_view = UserRolePermission(
    "Opening Stock Report",
    "view_permission"
  );
  let taxSummary_view = UserRolePermission("Tax Summary", "view_permission");
  // let VATReport_view = UserRolePermission("VAT Report", "view_permission");
  let SalesVATReport_view = UserRolePermission(
    "Sales VAT Report",
    "view_permission"
  );
  let PurchaseVATReport_view = UserRolePermission(
    "Purchase VAT Report",
    "view_permission"
  );
  let GSTR1Report_view = UserRolePermission("GSTR1 Report", "view_permission");
  let salesGSTReportReport_view = UserRolePermission(
    "Sales GST Report",
    "view_permission"
  );
  let purchaseGSTReport_view = UserRolePermission(
    "Purchase GST Report",
    "view_permission"
  );
  let loyalityCustomer_view = UserRolePermission(
    "Loyalty Customer",
    "view_permission"
  );
  let loyalityProgram_view = UserRolePermission(
    "Loyalty Program",
    "view_permission"
  );
  let workOrderReport_view = UserRolePermission(
    "Work Order",
    "view_permission"
  );
  let stockTransfer_view = UserRolePermission(
    "Stock Transfer",
    "view_permission"
  );
  let Report = UserRolePermission("Opening Stock", "view_permission");
  let stockManagement_view = UserRolePermission(
    "Stock Management",
    "view_permission"
  );
  let journal_view = UserRolePermission("Journal", "view_permission");
  let expense_view = UserRolePermission("Expense", "view_permission");
  let warehouse_view = UserRolePermission("Warehouse", "view_permission");
  let route_view = UserRolePermission("Route", "view_permission");
  let unit_view = UserRolePermission("Units", "view_permission");
  let pricecategory_view = UserRolePermission(
    "Price Category",
    "view_permission"
  );
  let transactiontype_view = UserRolePermission(
    "Transaction Types",
    "view_permission"
  );
  let creditnote_view = UserRolePermission("Credit Note", "view_permission");
  let debitnote_view = UserRolePermission("Debit Note", "view_permission");
  let purchase_bt = UserRolePermission("Purchase BT", "view_permission");
  let sales_bt = UserRolePermission("Sales BT", "view_permission");
  let sales_request = UserRolePermission("BT Request", "view_permission");
  let bankreconciliaton_view = UserRolePermission(
    "Bank Reconciliation",
    "view_permission"
  );
  let accountgroup_view = UserRolePermission(
    "Account Group",
    "view_permission"
  );
  let accountledger_view = UserRolePermission(
    "Account Ledger",
    "view_permission"
  );
  let bank_view = UserRolePermission("Banks", "view_permission");
  let taxcategory_view = UserRolePermission("Tax Category", "view_permission");
  let openingStcok_view = UserRolePermission(
    "Opening Stock",
    "view_permission"
  );
  let workOrder_view = UserRolePermission("Work Order", "view_permission");

  let manufacture_view = UserRolePermission("Manufacturing", "view_permission");
  let stock_order_view = UserRolePermission("Stock Order", "view_permission");

  // user roll settings ends here

  let is_StockOrder = GetEditionPermission(
    Edition,
    "StockOrder",
    GST,
    VAT,
    EnableProductBatchWise,
    GST,
    VAT
  );
  let manufacture = GetEditionPermission(
    Edition,
    "Manufacture",
    GST,
    VAT,
    EnableProductBatchWise,
    GST,
    VAT
  );
  let is_account_group = GetEditionPermission(
    Edition,
    "AccountGroup",
    GST,
    VAT,
    EnableProductBatchWise,
    GST,
    VAT
  );
  let is_loyality_customer = GetEditionPermission(
    Edition,
    "LoyaltyCustomer",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_loyality_program = GetEditionPermission(
    Edition,
    "LoyalityProgram",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_price_category = GetEditionPermission(
    Edition,
    "PriceCategory",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_routes = GetEditionPermission(
    Edition,
    "Route",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_transaction_types = GetEditionPermission(
    Edition,
    "TransactionType",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_stock_adjustments = GetEditionPermission(
    Edition,
    "StockAdjustment",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_excess_stock = GetEditionPermission(
    Edition,
    "ExcessStock",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_shortage_stock = GetEditionPermission(
    Edition,
    "ShortageStock",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_stock_transfer = GetEditionPermission(
    Edition,
    "StockTransfer",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_warehouse = GetEditionPermission(
    Edition,
    "Warehouse",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_bank_reconciliation = GetEditionPermission(
    Edition,
    "BankReconciliation",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_opening_stock = GetEditionPermission(
    Edition,
    "OpeningStock",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_work_order = GetEditionPermission(
    Edition,
    "WorkOrder",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_expense = GetEditionPermission(
    Edition,
    "Expense",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_journal = GetEditionPermission(
    Edition,
    "Journal",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let is_pos = false;
  if (BusinessTypeName === "Restaurant") {
    is_pos = true;
  }
  let IsbranchTransfer = GetEditionPermission(
    Edition,
    "BranchTransfer",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let IsdeliveryNote = GetEditionPermission(
    Edition,
    "DeliveryNote",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let IsDebitNote = GetEditionPermission(
    Edition,
    "DebitNote",
    GST,
    VAT,
    EnableProductBatchWise
  );
  let IsCreditNote = GetEditionPermission(
    Edition,
    "CreditNote",
    GST,
    VAT,
    EnableProductBatchWise
  );

  // if (Edition === "Lite") {
  //   is_account_group = false;
  //   is_loyality_customer = false;
  //   is_loyality_program = false;
  //   is_price_category = false;
  //   is_routes = false;
  //   is_transaction_types = false;
  //   is_stock_adjustments = false;
  //   is_excess_stock = false;
  //   is_shortage_stock = false;
  //   is_stock_transfer = false;
  //   is_warehouse = false;
  //   is_opening_stock = false;
  //   is_work_order = false;
  //   is_expense = false;
  // }

  let is_inventory = false;
  if (
    is_work_order ||
    is_opening_stock ||
    is_stock_transfer ||
    is_stock_adjustments
  ) {
    is_inventory = true;
  }

  let is_finance = false;
  if (is_expense || is_journal) {
    is_finance = true;
  }

  const [showMore, setMore] = useState(false);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMore(false);
          // alert("You clicked outside of me!");
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const get_report_status = () => {
    let is_reportOK = false;
    if (
      trialBalance_view ||
      profitAndLoss_view ||
      balanceSheet_view ||
      outStandingReport_view ||
      dailyReport_view ||
      cashBook_view ||
      bankBook_view ||
      receiptReport_view ||
      paymentReport_view ||
      ledgerReport_view ||
      billWiseReportReport_view ||
      salesReport_view ||
      salesSummaryReport_view ||
      salesRegisterReport_view ||
      salesReturnRegister_view ||
      salesIntegratedReport_view ||
      purchaseReport_view ||
      purchaseOrderReport_view ||
      purchaseRegisterReport_view ||
      purchaseReturnRegister_view ||
      supplierVsProduct_view ||
      stockReport_view ||
      stockValueReport_view ||
      stockLedgerReport_view ||
      productReport_view ||
      productAnalysisReport_view ||
      batchWiseReport_view ||
      excessStockReport_view ||
      shortageStock_view ||
      openingStockReport_view ||
      taxSummary_view ||
      SalesVATReport_view ||
      PurchaseVATReport_view ||
      GSTR1Report_view ||
      salesGSTReportReport_view ||
      purchaseGSTReport_view
    ) {
      is_reportOK = true;
    }
    return is_reportOK;
  };
  // console.log(props.Edition);
  let sales_Link = `${VIKNBOOKS_FRONT_URL}dashboard/sale-invoice-list`;
  if (sales_view) {
    sales_Link = EnableEinvoiceKSA ? `${Report_URL}list-sales` : sales_Link;
  } else if (salesReturn_view) {
    sales_Link = `${VIKNBOOKS_FRONT_URL}dashboard/sales-return-list`;
  } else if (salesOrder_view) {
    sales_Link = `${VIKNBOOKS_FRONT_URL}dashboard/sales-order-list`;
  } else if (salesEstimate_view) {
    sales_Link = `${VIKNBOOKS_FRONT_URL}dashboard/sales-estimate-list`;
  }
  let purchase_Link = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-invoice-list`;
  if (purchase_view) {
    purchase_Link = purchase_Link;
  } else if (purchaseReturn_view) {
    purchase_Link = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-return-list`;
  } else if (purchaseOrder_view) {
    purchase_Link = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-order-list`;
  } else if (purchaseEstimate_view) {
    purchase_Link = `${VIKNBOOKS_FRONT_URL}dashboard/purchase-estimate-list`;
  }

  let product_Link = `${VIKNBOOKS_FRONT_URL}dashboard/products`;
  if (product_view) {
    product_Link = product_Link;
  } else if (brands_view) {
    product_Link = `${VIKNBOOKS_FRONT_URL}dashboard/brands`;
  } else if (productGroup_view) {
    product_Link = `${VIKNBOOKS_FRONT_URL}dashboard/product-groups`;
  } else if (productCategory_view) {
    product_Link = `${VIKNBOOKS_FRONT_URL}dashboard/product-categories`;
  }

  let contact_Link = `${VIKNBOOKS_FRONT_URL}dashboard/customer-list`;
  if (customer_view) {
    contact_Link = contact_Link;
  } else if (supplier_view) {
    contact_Link = `${VIKNBOOKS_FRONT_URL}dashboard/supplier-list`;
  } else if (employees_view) {
    contact_Link = `${VIKNBOOKS_FRONT_URL}dashboard/employee-list`;
  }

  let loyality_Link = `${VIKNBOOKS_FRONT_URL}dashboard/loyalty-customer`;
  if (loyalityCustomer_view) {
    loyality_Link = loyality_Link;
  } else if (loyalityProgram_view) {
    loyality_Link = `${VIKNBOOKS_FRONT_URL}dashboard/loyalty-program`;
  }

  let inventory_Link = `${VIKNBOOKS_FRONT_URL}dashboard/create-opening-stock`;
  if (Report) {
    inventory_Link = inventory_Link;
  } else if (stockTransfer_view) {
    inventory_Link = `${VIKNBOOKS_FRONT_URL}dashboard/create-stock-transfer`;
  } else if (workOrderReport_view) {
    inventory_Link = `${VIKNBOOKS_FRONT_URL}dashboard/create-work-order`;
  } else if (stockManagement_view) {
    inventory_Link = `${VIKNBOOKS_FRONT_URL}dashboard/create-stock-adjustment`;
  }

  let accounts_link = "";
  if (accountledger_view) {
    accounts_link = `${VIKNBOOKS_FRONT_URL}dashboard/account-ledgers`;
  } else if (accountgroup_view) {
    accounts_link = `${VIKNBOOKS_FRONT_URL}dashboard/account-groups`;
  } else if (bankreconciliaton_view) {
    accounts_link = `${VIKNBOOKS_FRONT_URL}dashboard/banks`;
  } else {
    accounts_link = "";
  }

  let finance_Link = `${VIKNBOOKS_FRONT_URL}dashboard/journal`;
  if (journal_view) {
    finance_Link = finance_Link;
  }
  // else if (expense_view) {
  //   finance_Link = `${VIKNBOOKS_FRONT_URL}dashboard/expense-list";
  // }
  else {
    finance_Link = "";
  }

  let payment_link = `${VIKNBOOKS_FRONT_URL}dashboard/payment-voucher`;
  if (payment_view) {
    payment_link = payment_link;
  } else if (expense_view) {
    payment_link = `${VIKNBOOKS_FRONT_URL}dashboard/expense-list`;
  } else {
    payment_link = "";
  }

  let is_reportOK = get_report_status();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setsideMenuHeight(ref.current.clientHeight);
    }, 1000 * 2); // in milliseconds
    return () => clearInterval(intervalId);
  }, [ref]);
  const handleIconClick = (name) => {
    let URL;
    let destinationURL;
    switch (name) {
      case "Sales":
        destinationURL = sales_Link;
        break;
      case "Receipt":
        destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/receipts`;
        break;
      case "Purchase":
        destinationURL = purchase_Link;
        break;
      case "Payment":
        destinationURL = payment_link;
        break;
      case "Contacts":
        destinationURL = contact_Link;
        break;
      case "Products":
        destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/products`;
        break;
      case "Report":
        destinationURL = "/";
        break;
      default:
        destinationURL = "/";
    }
    // window.open(destinationURL);
    window.location.href = destinationURL;
  };

  return (
    <div ref={wrapperRef}>
      <SideMenuContainer showMore={showMore}>
        <SideMenuIconList ref={ref}>
          {sales_view ||
          salesReturn_view ||
          salesOrder_view ||
          salesEstimate_view ? (
            <SideMenuIconContainer onClick={() => handleIconClick("Sales")}>
              <SideMenuImg src={Icons.SalesLogo} />
            </SideMenuIconContainer>
          ) : null}

          {receipt_view ? (
            <SideMenuIconContainer onClick={() => handleIconClick("Receipt")}>
              <SideMenuImg src={Icons.Receipt} />
            </SideMenuIconContainer>
          ) : null}

          {purchase_view ||
          purchaseReturn_view ||
          purchaseOrder_view ||
          purchaseEstimate_view ? (
            <SideMenuIconContainer onClick={() => handleIconClick("Purchase")}>
              <SideMenuImg src={Icons.Purchase} />
            </SideMenuIconContainer>
          ) : null}
          {payment_view ? (
            <SideMenuIconContainer onClick={() => handleIconClick("Payment")}>
              <SideMenuImg src={Icons.Payment} />
            </SideMenuIconContainer>
          ) : null}
          {customer_view || supplier_view || employees_view ? (
            <SideMenuIconContainer onClick={() => handleIconClick("Contacts")}>
              <SideMenuImg src={Icons.Contacts} />
            </SideMenuIconContainer>
          ) : null}
          {product_view ||
          brands_view ||
          productGroup_view ||
          productCategory_view ? (
            <SideMenuIconContainer onClick={() => handleIconClick("Products")}>
              <SideMenuImg src={Icons.Products} />
            </SideMenuIconContainer>
          ) : null}

          {is_reportOK ? (
            <SideMenuIconContainer onClick={() => handleIconClick("Report")}>
              <SideMenuImg src={Icons.Report} />
            </SideMenuIconContainer>
          ) : null}

          <SideMenuIconContainer onClick={() => setMore(!showMore)}>
            <SideMenuImg src={Icons.More} />
          </SideMenuIconContainer>
        </SideMenuIconList>
        <SideMenuList className="SideMenuList">
          <SideMenuListContainer sideMenuHeight={sideMenuHeight}>
            {sales_view ||
            salesReturn_view ||
            salesOrder_view ||
            salesEstimate_view ? (
              <SideMenuSelect>
                <SideMenuTextLink to={sales_Link}>
                  {t("Sales")}
                </SideMenuTextLink>
              </SideMenuSelect>
            ) : null}
            {receipt_view ? (
              <SideMenuSelect>
                <SideMenuTextLink
                  to={`${VIKNBOOKS_FRONT_URL}dashboard/receipts`}
                >
                  {t("Receipt")}
                </SideMenuTextLink>
              </SideMenuSelect>
            ) : null}
            {purchase_view ||
            purchaseReturn_view ||
            purchaseOrder_view ||
            purchaseEstimate_view ? (
              <SideMenuSelect>
                <SideMenuTextLink to={purchase_Link}>
                  {t("Purchase")}
                </SideMenuTextLink>
              </SideMenuSelect>
            ) : null}
            {payment_view || expense_view ? (
              <SideMenuSelect>
                <SideMenuTextLink to={payment_link}>
                  {t("Payment")}
                </SideMenuTextLink>
              </SideMenuSelect>
            ) : null}
            {customer_view || supplier_view || employees_view ? (
              <SideMenuSelect>
                {EnableEinvoiceKSA ? (
                  <a
                    style={{
                      padding: "10px 10px",
                      display: "inline-block",
                      width: "100%",
                      color:"white",
                      fontSize:"12px",
                      textDecoration:"none"
                    }}
                    className="link"
                    href={Report_URL + "customer-list"}
                    // onClick={GoToNewReport}
                  >
                    {t("Contacts")}
                  </a>
                ) : (
                  <SideMenuTextLink to={contact_Link}>
                    {t("Contacts")}
                  </SideMenuTextLink>
                )}
              </SideMenuSelect>
            ) : null}
            {product_view ||
            brands_view ||
            productGroup_view ||
            productCategory_view ? (
              <SideMenuSelect>
                <SideMenuTextLink
                  to={`${VIKNBOOKS_FRONT_URL}dashboard/products`}
                >
                  {t("Products")}
                </SideMenuTextLink>
              </SideMenuSelect>
            ) : null}

            {is_reportOK ? (
              <SideMenuSelect>
                <SideMenuTextLink to={`/`}>{t("Report")}</SideMenuTextLink>
              </SideMenuSelect>
            ) : null}
            {accountgroup_view ||
            accountledger_view ||
            bank_view ||
            taxcategory_view ||
            openingStcok_view ||
            workOrder_view ||
            journal_view ||
            expense_view ||
            bankreconciliaton_view ||
            stockManagement_view ||
            stockTransfer_view ? (
              <SideMenuSelect onClick={() => setMore(!showMore)}>
                <MoreLink>{t("More")}</MoreLink>
              </SideMenuSelect>
            ) : null}
          </SideMenuListContainer>
        </SideMenuList>
        <MoreMenuContainer showMore={showMore}>
          <MoreIconLink onClick={() => setMore(!showMore)} to={accounts_link}>
            <MoreIcon>
              <MoreImg src={Icons.Accounts} />
            </MoreIcon>
            <MoreText>{t("Accounts")}</MoreText>
          </MoreIconLink>
          {EnableLoyaltySettings &&
          is_loyality_customer &&
          (loyalityCustomer_view || loyalityProgram_view) ? (
            <MoreIconLink onClick={() => setMore(!showMore)} to={loyality_Link}>
              <MoreIcon>
                <MoreImg src={Icons.Loyality} />
              </MoreIcon>
              <MoreText>{t("Loyalty")}</MoreText>
            </MoreIconLink>
          ) : null}
          {is_routes && route_view && EnableRoute ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/routes`}
            >
              <MoreIcon>
                <MoreImg src={Icons.Routes} />
              </MoreIcon>
              <MoreText>{t("Routes")}</MoreText>
            </MoreIconLink>
          ) : null}
          {unit_view ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/units`}
            >
              <MoreIcon>
                <MoreImg src={Icons.UNITS} />
              </MoreIcon>
              <MoreText>{t("Units")}</MoreText>
            </MoreIconLink>
          ) : null}
          {is_price_category && pricecategory_view ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/price-categories`}
            >
              <MoreIcon>
                <MoreImg src={Icons.PriceCategory} />
              </MoreIcon>
              <MoreText>{t("Price Category")}</MoreText>
            </MoreIconLink>
          ) : null}
          {is_finance && journal_view ? (
            <MoreIconLink onClick={() => setMore(!showMore)} to={finance_Link}>
              <MoreIcon>
                <MoreImg src={Icons.Financial} />
              </MoreIcon>
              <MoreText>{t("Finance")}</MoreText>
            </MoreIconLink>
          ) : null}
          {is_warehouse && warehouse_view && EnableWarehouse ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/warehouse`}
            >
              <MoreIcon>
                <MoreImg src={Icons.Warehouse} />
              </MoreIcon>
              <MoreText>{t("Warehouse")}</MoreText>
            </MoreIconLink>
          ) : null}
          {is_inventory &&
          (Report ||
            stockTransfer_view ||
            workOrderReport_view ||
            stockManagement_view) ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={inventory_Link}
            >
              <MoreIcon>
                <MoreImg src={Icons.Stock} />
              </MoreIcon>
              <MoreText>{t("Inventory")}</MoreText>
            </MoreIconLink>
          ) : null}
          {is_bank_reconciliation && bankreconciliaton_view ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/bank-reconciliation-list`}
            >
              <MoreIcon>
                <MoreImg src={Icons.BankReconciliation} />
              </MoreIcon>
              <MoreText>{t("Bank Reconciliation")}</MoreText>
            </MoreIconLink>
          ) : null}
          {stock_order_view && is_StockOrder && EnableWarehouse ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/create-stock-order`}
            >
              <MoreIcon>
                <MoreImg src={Icons.StockOrder} />
              </MoreIcon>
              <MoreText>{t("Stock Order")}</MoreText>
            </MoreIconLink>
          ) : null}
          {manufacture_view && manufacture ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/create-manufacturing`}
            >
              <MoreIcon>
                <MoreImg src={Icons.Manufacturing} />
              </MoreIcon>
              <MoreText>{t("Manufacturing")}</MoreText>
            </MoreIconLink>
          ) : null}

          {IsbranchTransfer && (purchase_bt || sales_bt || sales_request) ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={
                sales_bt
                  ? `${VIKNBOOKS_FRONT_URL}dashboard/sale-invoice-list-bt`
                  : purchase_bt
                  ? `${VIKNBOOKS_FRONT_URL}dashboard/purchase-invoice-list-bt`
                  : `${VIKNBOOKS_FRONT_URL}dashboard/bt-request-list`
              }
            >
              <MoreIcon>
                <MoreImg src={Icons.BranchTransfer} />
              </MoreIcon>
              <MoreText>{t("Branch Transfer")}</MoreText>
            </MoreIconLink>
          ) : null}

          {IsdeliveryNote ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/list-delivery-note`}
            >
              <MoreIcon>
                <MoreImg src={Icons.DeliveryNote} />
              </MoreIcon>
              <MoreText>{t("Delivery Note")}</MoreText>
            </MoreIconLink>
          ) : null}
          {creditnote_view && IsCreditNote ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/credit-note-list`}
            >
              <MoreIcon>
                <MoreImg src={Icons.CreditNote} />
              </MoreIcon>
              <MoreText>{t("Credit Note")}</MoreText>
            </MoreIconLink>
          ) : null}

          {debitnote_view && IsDebitNote ? (
            <MoreIconLink
              onClick={() => setMore(!showMore)}
              to={`${VIKNBOOKS_FRONT_URL}dashboard/debit-note-list`}
            >
              <MoreIcon>
                <MoreImg src={Icons.DebitNote} />
              </MoreIcon>
              <MoreText>{t("Debit Note")}</MoreText>
            </MoreIconLink>
          ) : null}
        </MoreMenuContainer>
      </SideMenuContainer>
    </div>
  );
};

export default SideNavigation;

const SideMenuList = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  left: -55,
  width: 100,
  zIndex: 9,
  opacity: 0,
  transformOrigin: "1px",
  transition: "all 500ms cubic-bezier(0.25, 0.25, 0.165, 1.325)",
}));

const SideMenuContainer = styled(Box)(({ theme, showMore }) => ({
  position: "relative",
  paddingLeft: 5,
  width: 55,
  borderRadius: 10,
  zIndex: 101,
  "&:hover": !showMore
    ? {
        // [`& ${SideMenuList}`]: {
        ".SideMenuList": {
          left: 60,
          opacity: 1,
          zIndex: 9,
          width: 115,
          transition: "all 500ms cubic-bezier(0.25, 0.25, 0.165, 1.325)",
        },
      }
    : {},
  "@media only screen and (max-height: 700px)": {
    transform: "scale(0.9)",
  },
  "@media only screen and (max-height: 670px)": {
    transform: "scale(0.8)",
    position: "absolute",
    top: 28,
    left: -3,
  },
}));

const SideMenuIconList = styled(Box)({
  padding: "6px 0px",
  background: "#212121",
  borderRadius: 10,
  height: "auto",
});

const SideMenuIconContainer = styled(Box)({
  width: 40,
  height: 40,
  padding: 7,
  margin: 5,
  borderRadius: 5,
  cursor: "pointer",
});

const SideMenuImg = styled("img")({
  width: "100%",
  height: "100%",
});

const SideMenuTextLink = styled(Link)({
  color: "#fff",
  padding: "10px 10px",
  display: "inline-block",
  width: "100%",
  fontSize: "12px",
  textDecoration: "none",
  "&:hover": {
    color: "#000",
  },
});

const MoreLink = styled(Box)({
  color: "#fff",
  padding: "10px 10px",
  display: "inline-block",
  width: "100%",
  userSelect: "none",
  fontSize: "12px",
  "&:hover": {
    color: "#000",
  },
});

const SideMenuSelect = styled(Box)({
  color: "#fff",
  borderRadius: 5,
  boxSizing: "border-box",
  "&:hover": {
    [`& ${SideMenuTextLink}`]: {
      color: "#000",
    },
    cursor: "pointer",
    color: "#000",
    background: "#fff",
  },
});

const SideMenuListContainer = styled(Box)(({ sideMenuHeight }) => ({
  background: "#212121",
  padding: "0 5px",
  borderRadius: 10,
  height: sideMenuHeight > 0 ? `${sideMenuHeight}px` : "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
}));

const MoreMenuContainer = styled(Box)(({ showMore }) => ({
  overflow: "auto",
  opacity: showMore ? 1 : 0.5,
  left: showMore ? 60 : "-300px",
  transform: showMore ? "initial" : "scale(.1)",
  transition: "all ease-in-out 0.3s",
  position: "absolute",
  background: "#212121",
  width: "max-content",
  maxHeight: "380px",
  height: "max-content",
  borderRadius: 10,
  padding: 10,
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  placeItems: "center",
  zIndex: 15,
  top: 0,
  bottom: 0,
  margin: "auto 0",
  // scrollbarWidth: "thin",
  // "&::-webkit-scrollbar": {
  //   width: 5,
  // },
  // "&::-webkit-scrollbar-track": {
  //   background: "#fff",
  //   borderRadius: 15,
  // },
  // "&::-webkit-scrollbar-thumb": {
  //   background: "#0A9EF3",
  //   borderRadius: 15,
  // },
}));

const MoreText = styled(Box)({
  color: "#fff",
  textAlign: "center",
  transition: "all ease-in-out 0.3s",
  fontSize: 10,
});

const MoreIconLink = styled(Link)({
  margin: 3,
  color: "#000",
  borderRadius: 8,
  width: 80,
  height: 80,
  transition: "all ease-in-out 0.3s",
  "&:hover": {
    transition: "all ease-in-out 0.3s",
    backgroundColor: "#383838",
  },
});

const MoreIcon = styled(Box)({
  width: 50,
  height: 50,
  padding: 7,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
});

const MoreImg = styled("img")({
  width: "100%",
  height: "100%",
});
