import axios from "axios";

export const callStockTransferPageDetailsAPI = async function (body) {

    try {
      const { data } = await axios.post("api/v11/stock-transfer/stock-transfer-page-details/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};

export const callProductGroupAPI = async function (body) {

  try {
    const { data } = await axios.post("/api/v10/productGroups/productGroups/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};



export const callStockTransferFilterAPI = async function (body) {

  try {
    const { data } = await axios.post("api/v10/openingStock/StockTransfer-filter/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


