import {
  Box,
  Button,
  Paper,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductDetail from "./Components/ProductDetail";
import MultiUnits from "./Components/MultiUnits";
import { get_VoucherNo, unit_list } from "../../../Api/CommonApi/CommonApis";
import { useSelector } from "react-redux";
import {
  GetRowMaterialUrl,
  GetSingleRowmaterialUrl,
  ProductGroupAPI,
} from "../../../Api/Products/ProductApis";
import {
  CallBrands,
  CallTaxListByType,
} from "../../../Api/Reports/CommonReportApi";
import Bom from "./Components/Bom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { jsObjtoFormData } from "../../../generalFunction";

// Replace this with the Vikn theme fiele color
const VIKNBLUE = "#0A9EF3";

const DefaultMultiUnit = {
  UnitName: "",
  UnitID: 1,
  SalesPrice: 0,
  MinimumSalesPrice: 0,
  PurchasePrice: 0,
  MultiFactor: "",
  ReverseFactor: "",
  Barcode: "",
  SalesPrice1: 0,
  SalesPrice2: 0,
  SalesPrice3: 0,
  MRP: "",
  UnitInSales: true,
  UnitInPurchase: true,
  UnitInReports: true,
  DefaultUnit: true,
};

const DefaultBomList = {
  Barcode: "",
  UnitName: null,
  ProductName: null,
  Qty: 1,
  UnitPrice: 0,
  UnitList: [],
  NetAmount: 0,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {/* {value === index && <Box sx={{ p: 3 }}>{children}</Box>} */}
      <Box sx={{ height: "100%" }}>{children}</Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateProduct = () => {
  const navigate = useNavigate();

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const {
    GST,
    // PriceDecimalPoint,
    // VAT,
    // PriceCategory,
    // EnableVoucherNoUserWise,
    // EnableTransilationInProduct,
    // MultiUnit,
    // ShowWarrantyPeriodInProduct,
    // EnableExciseTax,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const [value, setValue] = useState(0);

  const [dataDetail, setDataDetail] = useState({
    ProductCode: "",
    ProductName: "",
    DisplayName: "",
    Description: "",
    InventoryType: "",
    WarrantyType: "Year",
    HSNCode: "",

    searchBomText: "",

    VatID: 0,
    BrandID: 0,
    StockMinimum: 0,
    StockOrder: 0,
    StockMaximum: 0,
    MarginPercent: 0,
    ProductGroupID: 0,
    CostWithExpense: 0,
    Warranty: 1,
    AverageCost: 0,
    PLUNo: 0,

    ManualProductCode: false,
    is_Service: false,
    is_Weighing: false,
    is_RawMaterial: false,
    is_FinishedProduct: false,
    is_Sales: false,
    is_Purchase: false,
    is_Favourite: false,
    is_Active: false,
    is_Inclusive: false,
    is_BOM: false,
    is_KFC: false,

    WeighingCalcType: null,
    productGroup: null,
    brand: null,
    unit: null,
    taxType: null,
    Tax1: null,
    Tax2: null,
    Tax3: null,
    ExciseTaxID: null,

    ProductImages: [],
    BarcodeDetails: [],
    deleted_bom: [],
    multiUnit: [DefaultMultiUnit],
    BomList: [DefaultBomList],
  });

  console.log(dataDetail.searchBomText);

  const [dataList, setDataList] = useState({
    priductGroupList: [],
    unitList: [],
    brandList: [],
    taxList: [],
    productList: [],
    InventoryTypes: ["StockItem", "Non StockItem"],
    WeighingCalcTypes: ["Amount And Qty", "Amount", "Qty"],
    WarrantyTypes: ["Year", "Month"],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const AddNewMultiUnitLine = function () {
    setDataDetail({
      ...dataDetail,
      multiUnit: [
        ...dataDetail.multiUnit,
        {
          ...DefaultMultiUnit,
          UnitInSales: false,
          UnitInPurchase: false,
          UnitInReports: false,
          DefaultUnit: false,
        },
      ],
    });
  };

  const AddNewBomList = function () {
    setDataDetail({
      ...dataDetail,
      BomList: [
        ...dataDetail.BomList,
        {
          ...DefaultBomList,
        },
      ],
    });
  };

  async function callPreFetch() {
    await get_VoucherNo({
      BranchID,
      CompanyID,
      UserID: user_id,
      VoucherType: "PC",
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataDetail((prev) => ({ ...prev, ProductCode: e.VoucherNo }));
      }
    });

    await ProductGroupAPI({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataList((prev) => ({
          ...prev,
          priductGroupList: e.data,
        }));

        setDataDetail((prev) => ({
          ...prev,
          productGroup: e.data?.[0] || null,
        }));
      }
    });

    await unit_list({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataList((prev) => ({
          ...prev,
          unitList: e.data,
        }));

        setDataDetail((prev) => ({
          ...prev,
          unit: e.data?.[0] || null,
          multiUnit: [
            {
              ...prev.multiUnit[0],
              UnitName: e.data?.[0] ? e.data[0].UnitName : "",
            },
            ...prev.multiUnit.slice(1),
          ],
        }));
      }
    });

    await CallBrands({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataList((prev) => ({
          ...prev,
          brandList: e.data,
        }));
        setDataDetail((prev) => ({ ...prev, brand: e.data?.[0] || null }));
      }
    });

    await CallTaxListByType({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
      TaxType: 1,
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataList((prev) => ({
          ...prev,
          taxList: e.data,
        }));
        setDataDetail((prev) => ({ ...prev, taxType: e.data?.[0] || null }));
      }
    });
  }

  async function BomPreFetch() {
    await GetRowMaterialUrl({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
      Search: dataDetail.searchBomText,
      Type: "Product",
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataList((prev) => ({
          ...prev,
          productList: e.data,
        }));
      }
    });
  }

  async function callBomProdUnit(id) {
    try {
      const response = await GetSingleRowmaterialUrl({
        BranchID,
        CompanyID,
        CreatedUserID: user_id,
        Inclusive: false,
        ProductID: id,
        type: "Product",
      });

      if (response.StatusCode === 6000) {
        console.log(response);
        return response.data;
      } else {
        console.log("Error in GetSingleRowmaterialUrl:", response);
        return null;
      }
    } catch (error) {
      console.error("Error in callBomProdUnit:", error);
      return null;
    }
  }

  const submitProduct = function () {
    // | Create and edit in this same function

    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      ProductName: dataDetail.ProductName,
      ProductCode: dataDetail.ProductCode,
      DisplayName: dataDetail.DisplayName,
      Description: dataDetail.Description,
      ProductGroupID: dataDetail.ProductGroupID,
      BrandID: dataDetail.BrandID,
      InventoryType: dataDetail.InventoryType,
      VatID: dataDetail.VatID,
      MinimumSalesPrice: dataDetail.BrandID,
      StockMinimum: dataDetail.StockMinimum,
      StockReOrder: dataDetail.StockOrder,
      StockMaximum: dataDetail.StockMinimum,
      MarginPercent: dataDetail.MarginPercent,
      ProductImage: dataDetail.ProductImages[0],
      ProductImage2: dataDetail.ProductImages[1],
      ProductImage3: dataDetail.ProductImages[2],
      ProductImage4: dataDetail.ProductImages[3],
      ProductImage5: dataDetail.ProductImages[4],
      Active: dataDetail.is_Active,
      IsRawMaterial: dataDetail.is_RawMaterial,
      IsWeighingScale: dataDetail.is_Weighing,
      IsFinishedProduct: dataDetail.is_FinishedProduct,
      IsSales: dataDetail.is_Sales,
      IsPurchase: dataDetail.is_Purchase,
      WeighingCalcType: dataDetail.is_Weighing,
      PLUNo: dataDetail.PLUNo,
      IsFavourite: dataDetail.is_Favourite,
      HSNCode: dataDetail.HSNCode,
      IsKFC: dataDetail.is_KFC,
      GST: GST,
      Tax1: dataDetail.Tax1,
      Tax2: dataDetail.Tax2,
      Tax3: dataDetail.Tax3,
      WarrantyType: dataDetail.WarrantyType,
      Warranty: dataDetail.Warranty,
      is_Service: dataDetail.is_Service,
      is_inclusive: dataDetail.is_Inclusive,
      PriceListDetails: dataDetail.multiUnit,
      BarcodeDetails: dataDetail.BarcodeDetails,
      IsBOM: dataDetail.is_BOM,
      BomList: dataDetail.BomList,
      deleted_bom: dataDetail.deleted_bom,
      PurchasePrice: dataDetail.multiUnit[0].PurchasePrice,
      ExciseTaxID: dataDetail.ExciseTaxID,
      AverageCost: dataDetail.AverageCost,
      CostWithExpense: dataDetail.CostWithExpense,
    };

    jsObjtoFormData(payload);
  };


  useEffect(() => {callPreFetch()}, []);

  useEffect(() => {BomPreFetch()}, [dataDetail.searchBomText]);

  console.log(dataDetail, "DSAEATEAERERE");

  return (
    <Paper sx={{ width: "100%" }}>
      <Box
        sx={{
          p: 3,
          borderColor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "36px",
              color: "#001746",
            }}
          >
            Create Product
          </Typography>
          <Button
            sx={{
              fontSize: "15px",
              textTransform: "none",
              px: 2,
            }}
            variant="text"
            color="secondary"
            component={Link}
            to="/products"
          >
            View List
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Button
            sx={{
              fontSize: "15px",
              textTransform: "none",
              px: 2,
              color: VIKNBLUE,
            }}
            variant="text"
            onClick={() => {
              // navigate("/products");
              navigate("/create-products", { replace: true });
            }}
            // component={Link}
            // to="/create-products"
          >
            Cancel
          </Button>
          <BlueButton sx={{ px: 4 }} onClick={() => {}}>
            Save
          </BlueButton>
          {/* <Button variant="contained" color="secondary">Save</Button> */}
        </Box>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: VIKNBLUE,
              height: "4px",
            },
            ".MuiButtonBase-root": {
              textTransform: "none",
              width: "160px",
            },
            "& .Mui-selected": {
              color: `${VIKNBLUE} !important`,
            },
          }}
        >
          <Tab
            label="Product Details"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(0)}
          />
          <Tab
            label={dataDetail.is_BOM ? "BOM" : "Multi Units"}
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <ScrollBox>
        <CustomTabPanel value={value} index={0}>
          <ProductDetail
            dataDetail={dataDetail}
            setDataDetail={setDataDetail}
            dataList={dataList}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          {dataDetail.is_BOM ? (
            <Bom
              dataDetail={dataDetail}
              dataList={dataList}
              setDataDetail={setDataDetail}
              AddNewBomList={AddNewBomList}
              callBomProdUnit={callBomProdUnit}
            />
          ) : (
            <MultiUnits
              dataDetail={dataDetail}
              setDataDetail={setDataDetail}
              AddNewMultiUnitLine={AddNewMultiUnitLine}
              dataList={dataList}
            />
          )}
        </CustomTabPanel>
      </ScrollBox>
    </Paper>
  );
};

export default CreateProduct;

const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  borderRadius: "6px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));

const ScrollBox = styled(Box)(() => ({
  height: "calc(100vh - 208px)",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "&::-webkit-scrollbar-track": {
    display: "none",
  },
}));

// CompanyID: 8218b767-6897-48d7-a559-5f0b09fd7a56
// BranchID: 1
// CreatedUserID: 829
// ProductName: conn
// ProductCode: PC1142
// DisplayName: conn
// Description:
// ProductGroupID: 2
// BrandID: 2
// InventoryType: StockItem
// VatID: 11
// MinimumSalesPrice: 0
// StockMinimum: 0.00
// StockReOrder: 0.00
// StockMaximum: 0.00
// MarginPercent: 0
// ProductImage:
// ProductImage2:
// ProductImage3:
// ProductImage4:
// ProductImage5:
// Active: true
// IsRawMaterial: false
// IsWeighingScale: false
// IsFinishedProduct: false
// IsSales: true
// IsPurchase: true
// WeighingCalcType: Amount
// PLUNo: 0
// IsFavourite: false
// HSNCode:
// IsKFC: false
// GST: null
// Tax1: null
// Tax2: null
// Tax3: null
// WarrantyType: Year
// Warranty: 1
// is_Service: false
// is_inclusive: false
// PriceListDetails: [{"UnitName":"","UnitID":3,"SalesPrice":0,"PurchasePrice":0,"MultiFactor":1,"ReverseFactor":"","Barcode":"","SalesPrice1":"0","SalesPrice2":"0","SalesPrice3":"0","MRP":0,"UnitInSales":true,"UnitInPurchase":true,"UnitInReports":true,"DefaultUnit":true,"detailID":0},{"UnitName":"","UnitID":"","SalesPrice":0,"PurchasePrice":0,"MultiFactor":"","ReverseFactor":"1","Barcode":"","SalesPrice1":"0","SalesPrice2":"0","SalesPrice3":"0","MRP":0,"UnitInSales":false,"UnitInPurchase":false,"UnitInReports":false,"DefaultUnit":false,"detailID":0}]
// BarcodeDetails: [{"barcode":"","unit":1}]
// IsBOM: false
// BomList: [{"Barcode":"","ProductCode":{"ProductCode":""},"ProductName":{"ProductName":""},"Qty":1,"UnitPrice":0,"UnitList":[{"UnitName":""}],"NetAmount":0}]
// deleted_bom: []
// PurchasePrice: 0
// ExciseTaxID: null
// AverageCost: 0
// CostWithExpense: 0
