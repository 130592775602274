import {
  Box,
  Divider,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  CallFaeraReport,
  CallFaeraReportSingle,
} from "../../../../Api/Reports/AccountApi";
import lodash from "lodash";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import SummaryTable from "./Components/SummaryTable";
import DetailsTable from "./Components/DetailsTable";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import FilterLable from "../../../../Components/Utils/FilterLable";
import ExportToExcelButton from "../../../../Components/Utils/ExportToExcelButton";
import { UserRolePermission } from "../../../../Function/Editions";
import PaperLoading from "../../../../Components/Utils/PaperLoading";
import { CallUsers } from "../../../../Api/Reports/CommonReportApi";

const FearaReport = () => {
  let Export_view = UserRolePermission("Faera Report", "print_permission");
  const appSettings = useSelector((state) => state.appSettingsSclice);
  const [t, i18n] = useTranslation("common");
  const [page_no, setPage_no] = useState(1);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { user_id } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);

  const [errorLoading, setErrorLoading] = useState({
    isReportLoad: false,
    isReportLoadSingle: false,
    isDevice: false,
    isfilter: false,
  });

  const [filterData, setFilterData] = useState({
    reportList: [],
    userList: [],
    reportListSingle: {
      total_cash: 0,
      total_bank: 0,
      total_received: 0,
      sales_data: [],
      sales_total: {
        Total_NetAmount: 0,
        Total_Discount: 0,
        Total_Amount: 0,
        cash_amount: 0,
        bank_amount: 0,
        credit_sales: 0,
      },
      return_data: [],
      return_total: {
        Total_NetAmount: 0,
        Total_Discount: 0,
        Total_Amount: 0,
        cash_amount: 0,
        bank_amount: 0,
        credit_return: 0,
      },
      payment_data: [],
      payment_total: {
        Total_NetAmount: 0,
        Total_Discount: 0,
        Total_Amount: 0,
        cash_amount: 0,
        bank_amount: 0,
      },
      reciept_data: [],
      reciept_total: {
        Total_NetAmount: 0,
        Total_Discount: 0,
        Total_Amount: 0,
        cash_amount: 0,
        bank_amount: 0,
      },
      order_data: [],
      order_total: {
        Total_NetAmount: 0,
        Total_Discount: 0,
        Total_Amount: 0,
      },
      expense_data: [],
      expense_total: {
        Total_NetAmount: 0,
        Total_Discount: 0,
        Total_Amount: 0,
        cash_amount: 0,
        bank_amount: 0,
      },
    },
    switchReport: true,
    filterByList: [
      {
        id: 1,
        value: "Voucher Date",
      },
      {
        id: 2,
        value: "Created Date",
      },
    ],
  });

  const [filterOptions, setFilterOptions] = useState({
    FromDate: moment(),
    ToDate: moment(),
    selectDevice: null,
    filterBy: null,
    userSummary: null,
    userDetail: null,
  });
  const [expanded, setExpanded] = useState([]);
  const [expandOneAtATime, setExpandOneAtATime] = useState(true);

  // Handle Function
  const closeFilter = () => setOpenFilter(false);

  const searchReport = function () {
    console.log(filterOptions);
    if (filterData.switchReport) {
      fetchReport();
    } else {
      let whoIsNull = {
        device: false,
        filter: false,
      };
      if (filterOptions.selectDevice === null) whoIsNull.device = true;
      if (filterOptions.filterBy === null) whoIsNull.filter = true;

      console.log(
        whoIsNull,
        "filter is here  &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&******************************8"
      );
      if (!whoIsNull.device && !whoIsNull.filter) {
        fetchReportSingle();
      } else {
        setOpenFilter(true);
      }
      setErrorLoading({
        ...errorLoading,
        isfilter: whoIsNull.filter,
        isDevice: whoIsNull.device,
      });
    }
  };

  const handleClear = function () {
    setFilterOptions({
      FromDate: moment(),
      ToDate: moment(),
      selectDevice: null,
      filterBy: null,
    });
    // fetchReport()
    setClearFilter(!clearFilter);
  };

  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) =>
      isExpanded && expandOneAtATime
        ? [panel]
        : isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((item) => item !== panel)
    );
  };

  // Fetch API Data

  const fetchUser = function () {
    CallUsers({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      is_pos: true,
    }).then((res) => {
      if (res.StatusCode === 6000) {
        setFilterData(prev =>({
          ...prev,
          userList: res.data,
        }));
      }
    });
  };

  const fetchReport = async function () {
    setErrorLoading({ ...errorLoading, isReportLoad: true });
    const res = await CallFaeraReport({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
      UserID: filterOptions.userSummary?.UserID
    });

    if (res?.StatusCode === 6000) {
      setFilterData(prev => ({
        ...prev,
        reportList: res.data,
      }));
    }
    setErrorLoading({ ...errorLoading, isReportLoad: false });
  };

  const fetchReportSingle = async function () {
    console.log("Inside the fera report", filterOptions.selectDevice);
    setErrorLoading({ ...errorLoading, isReportLoadSingle: true });

    const payload = {
      FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
      DeviceCode: null,
      FilterBy: null,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      UserID: filterOptions.userDetail?.UserID || ""
    };
    if (filterOptions.selectDevice)
      payload.DeviceCode = filterOptions.selectDevice.DeviceCode;
    if (filterOptions.filterBy) payload.FilterBy = filterOptions.filterBy.id;

    const res = await CallFaeraReportSingle(payload);
    // console.log(res);
    if (res?.StatusCode === 6000) {
      setFilterData(prev => ({
        ...prev,
        reportListSingle: res,
      }));
    }
    setErrorLoading({ ...errorLoading, isReportLoadSingle: false });
  };

  useEffect(() => {
    fetchUser();
    fetchReport();
  }, []);

  useEffect(() => {
    if (filterData.switchReport) {
      fetchReport();
    } else {
      fetchReportSingle();
      // fetchUser();
    }
  }, [clearFilter]);

  return (
    <>
      <PaperLoading isLoading={errorLoading.isReportLoad}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                color: "#001746",
                fontSize: "23px",
                fontWeight: "500",
                mr: 2,
              }}
              variant="h2"
            >
              {t("Faera Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {/* <ExportBtn /> */}
            {Export_view ? (
              <ExportToExcelButton
                // VoucherType={state.is_gst ? "GST" : state.is_vat ? "VAT" : null}
                // BranchID={state.BranchID}
                // PriceRounding={state.PriceRounding}
                download_type={"PDF"}
                FromDate={filterOptions.FromDate.format("YYYY-MM-DD")}
                ToDate={filterOptions.ToDate.format("YYYY-MM-DD")}
                VoucherType={filterOptions.filterBy?.id}
                ProductID={filterOptions.selectDevice?.id}
                report_type="faera_report"
                StockFilter={filterData.switchReport ? 1 : 2}
                // ExportToXLS={ExportTaxSummaryReportExcel}
              />
            ) : null}
            {Export_view ? <PrintBtn /> : null}
            {/* <Pagenation
            totalItem={reportData.total_rows}
            page_no={page_no}
            setPage_no = {setPage_no}
            /> */}
          </FlexBox>
        </Box>
        <Box
          sx={{
            px: "26px",
            py: "10px",
            overflowX: "scroll",
            // // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
            alignItems: "center",
          }}
        >
          <FilterLable
            name={t("Date")}
            value={
              filterOptions.FromDate.format("DD/MM/YYYY") +
              " - " +
              filterOptions.ToDate.format("DD/MM/YYYY")
            }
          />
          {!filterData.switchReport && filterOptions.selectDevice && (
            <FilterLable
              name={t("Selected Device")}
              value={filterOptions.selectDevice.Name}
              handleDelete={() =>
                setFilterOptions({ ...filterOptions, selectDevice: null })
              }
            />
          )}
          {!filterData.switchReport && filterOptions.filterBy && (
            <FilterLable
              name={t("Filter By")}
              value={filterOptions.filterBy.value}
              handleDelete={() =>
                setFilterOptions({ ...filterOptions, filterBy: null })
              }
            />
          )}
        </Box>
        {filterData.switchReport ? (
          <SummaryTable
            filterData={filterData}
            isLoading={errorLoading.isReportLoad}
          />
        ) : (
          <DetailsTable
            expanded={expanded}
            handleChangeExpand={handleChangeExpand}
            filterData={filterData.reportListSingle}
            isLoading={errorLoading.isReportLoadSingle}
          />
        )}
      </PaperLoading>

      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={() => searchReport()}
        handleDefaultFilter={() => handleClear()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <ViknRadioGroup
                value={filterData.switchReport}
                radios={[
                  {
                    value: true,
                    label: t("Summary"),
                  },
                  {
                    value: false,
                    label: t("Detailed"),
                  },
                ]}
                handleChange={(e) =>
                  setFilterData((prev) => ({
                    ...prev,
                    switchReport: "true" === e.target.value,
                  }))
                }
              />
            </ListItem>

            <Divider />

            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.FromDate}
                toDate={filterOptions.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    FromDate: value,
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    ToDate: value,
                  })
                }
              />
            </ListItem>

            {!filterData.switchReport && (
              <ListItem>
                <ViknAutoComplete
                  error={errorLoading.isDevice}
                  placeholder={"-- Select Device --"}
                  value={filterOptions.selectDevice}
                  options={filterData.reportList}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({
                      ...prev,
                      selectDevice: newValue,
                    }))
                  }
                  getOptionLabel={(option) => option.Name}
                />
              </ListItem>
            )}

            {!filterData.switchReport && (
              <ListItem>
                <ViknAutoComplete
                  error={errorLoading.isfilter}
                  placeholder={"Filter By"}
                  value={filterOptions.filterBy}
                  options={filterData.filterByList}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({
                      ...prev,
                      filterBy: newValue,
                    }))
                  }
                  getOptionLabel={(option) => option?.value}
                />
              </ListItem>
            )}

            {(
              <ListItem>
                <ViknAutoComplete
                  error={errorLoading.isfilter}
                  placeholder={"Users"}
                  value={!filterData.switchReport ? filterOptions.userDetail : filterOptions.userSummary}
                  options={filterData.userList}
                  onChange={(e, newValue) =>
                    filterData.switchReport ? 
                    setFilterOptions((prev) => ({
                      ...prev,
                      userSummary: newValue,
                    }))
                    :
                    setFilterOptions((prev) => ({
                      ...prev,
                      userDetail: newValue,
                    }))
                  }
                  getOptionLabel={(option) => option?.customer_name}
                />
              </ListItem>
            )}
          </List>
        }
      />
    </>
  );
};

export default FearaReport;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
