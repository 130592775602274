import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from '@mui/icons-material/Clear';
import React, { useState } from "react";
import VBInputField from "../../../../Components/Utils/VBInputField";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import VBSelect from "../../../../Components/Utils/VBSelect";

// Replace this with the Vikn theme fiele color
const VIKNBLUE = "#0A9EF3";

function ProductDetail({ dataDetail, setDataDetail, dataList }) {

  const [openBarModal, setOpenBarModal] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleImage = function (e) {
    let ing = e.target?.files;

    if (ing) {
      let newImages = [];
      
      // Get the current number of images already added
      const currentImageCount = dataDetail.ProductImages.length;
      
      // If adding the new images exceeds 5, limit them
      if (currentImageCount + ing.length > 5) {
        // alert("You can only upload a maximum of 5 images");
        // Limit the new images so the total doesn't exceed 5
        // AlertMsg();
        const allowedImages = 5 - currentImageCount;
        newImages = Array.from(ing).slice(0, allowedImages);
      } else {
        newImages = Array.from(ing);
      }
  
      setDataDetail({
        ...dataDetail,
        ProductImages: [...dataDetail.ProductImages, ...newImages],
      });
    }
  };

  const removeImg = function (i) {
    setDataDetail((prev) => ({
      ...prev,
      ProductImages: prev.ProductImages.filter((o, ind) => ind !== i),
    }));
  };

  const handleAddImage = function () {
    let btn = document.getElementById("uploadPhoto");
    btn.click();
  };

  return (
    <>
      <Box sx={{ px: 4, pt: 4 }}>
        <Typography
          sx={{ color: "#001746", fontSize: "13px", fontWeight: "500", mb: 2 }}
        >
          Product Images
        </Typography>

        <Box sx={{ flexGrow: 1, width: "75%", mb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Paper
                elevation={0}
                sx={{
                  border: 2,
                  borderColor: "#D1D1D1",
                  borderStyle: "dashed",
                  // border: "5px solid transparent",
                  // borderImage:
                  //   "repeating-linear-gradient(to right, black 0%, black 10%, transparent 10%, transparent 20%) 1",
                  borderRadius: "6px",
                  backgroundColor: "#FAFAFA",
                  p: 3,

                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                }}
              >
                <input
                  type="file"
                  id="uploadPhoto"
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple={true}
                  onChange={handleImage}
                />
                <Button
                  variant="contained"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#F5F5F5",
                    border: 1,
                    borderColor: "#D1D1D1",
                    boxShadow: "none",
                    height: "148px",
                    minWidth: "140px",
                    color: "#525252",
                    fontSize: "10px",
                    ":hover": {
                      backgroundColor: "#F5F5F5",
                    },
                  }}
                  onClick={handleAddImage}
                >
                  {/* Click to upload */}
                  <img src="../images/addImage.png" alt="btn" />
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    overflowX: "scroll",
                    "-ms-overflow-style": "none",
                    scrollbarWidth: "none",
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                    width: "100%",
                  }}
                  // component={TransitionGroup}
                >
                  {dataDetail.ProductImages.map((e, i) => (
                    // <Collapse key={i} orientation="vertical" in={Boolean(i)}>
                    <Badge
                      onMouseEnter={() => setIsHover(i)}
                      onMouseLeave={() => setIsHover(null)}
                      sx={{
                        "&:hover": {
                          "& .MuiBadge-badge": {
                            display: "block",
                          },
                        },

                        "& .MuiBadge-badge": {
                          display: "none",
                          top: "45%",
                          left: "50%",
                          transform: "translate(-35%, -35%)",
                          zIndex: 0,
                        },
                      }}
                      badgeContent={
                        <IconButton
                          sx={{ color: "#EEEEEE" }}
                          onClick={() => removeImg(i)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={URL.createObjectURL(e)}
                        sx={{
                          filter: isHover === i && "blur(1.5px)",
                          height: "148px",
                          width: "140px",
                          borderRadius: "3px",
                          // zIndex: 100
                        }}
                      />
                    </Badge>
                    // </Collapse>
                  ))}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={4} alignContent={"center"}>
              <Grid
                container
                rowSpacing={2}
                direction="row"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12}>
                  <VBInputField
                    name="Product Code"
                    label="Product Code"
                    type="text"
                    value={dataDetail.ProductCode}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        ProductCode: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VBInputField
                    name="Display Name"
                    label="Display Name"
                    type="text"
                    value={dataDetail.DisplayName}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        DisplayName: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, width: "75%", mb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {/* AutoComplete ⭐⭐⭐ */}
              <ViknAutoComplete
                disablePortal
                name="Brand"
                label="Brand"
                id="custom-input-demo"
                value={dataDetail.brand}
                options={dataList.brandList}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    brand: v,
                  });
                }}
                renderInput={(params) => (
                  <VBInputField type="text" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <ViknAutoComplete
                disablePortal
                name="Product Group"
                label="Product Group"
                id="custom-input-demo"
                value={dataDetail.productGroup}
                options={dataList.priductGroupList}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    productGroup: v,
                  });
                }}
                renderInput={(params) => (
                  <VBInputField label="Product Group" type="text" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                name="Product Name"
                label="Product Name"
                type="text"
                value={dataDetail.ProductName}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    ProductName: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <VBInputField
                name="Cost With Expanse"
                label="Cost With Expanse"
                type="text"
                value={dataDetail.CostWithExpense}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    CostWithExpense: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {/* <VBInputField
                label="Warranty"
                type="text"
                value={dataDetail.WarrantyType}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    WarrantyType: e.target.value,
                  });
                }}
              /> */}

              <VBSelect
                name="Warranty"
                label="Warranty"
                value={dataDetail.WarrantyType}
                options={["Year", "Months"]}
                handleChange={(e) =>
                  setDataDetail({ ...dataDetail, WarrantyType: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                name="Average Cost"
                label="Average Cost"
                type="number"
                value={dataDetail.AverageCost}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    AverageCost: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <VBInputField
                name="Description"
                label="Description"
                type="text"
                row={4}
                value={dataDetail.Description}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    Description: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Box sx={{ m: 3 }} />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="This Is Service"
                control={
                  <Checkbox
                    name="is_Service"
                    checked={dataDetail.is_Service}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        is_Service: !dataDetail.is_Service,
                      });
                    }}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />

              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Manual Product Codes"
                control={
                  <Checkbox
                    checked={dataDetail.ManualProductCode}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        is_Service: !dataDetail.ManualProductCode,
                      });
                    }}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Divider variant="fullWidth" sx={{ mb: 5 }} />

      <Box sx={{ px: 4 }}>
        <Typography
          sx={{ color: "#001746", fontSize: "13px", fontWeight: "500", mb: 5 }}
        >
          Pricing and Inventory Information
        </Typography>
        <Box sx={{ flexGrow: 1, width: "75%", mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <VBInputField
                label="Purchase Price"
                type="number"
                value={dataDetail.multiUnit[0].PurchasePrice}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    DefaultSalesPrice: e.target.value,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        PurchasePrice: e.target.value,
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Sales Price"
                type="number"
                value={dataDetail.multiUnit[0].SalesPrice}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        SalesPrice: e.target.value,
                        MRP:
                          (dataDetail.taxType.SalesTax / 100) * e.target.value +
                          Number(e.target.value),
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Minimum Price"
                type="number"
                value={dataDetail.multiUnit[0].MinimumSalesPrice}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        MinimumSalesPrice: e.target.value,
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <VBInputField
                label="MRP"
                type="number"
                value={dataDetail.multiUnit[0].MRP}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        MRP: e.target.value,
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {/* AutoComplete $$$$ */}

              <ViknAutoComplete
                disablePortal
                label="VAT"
                id="custom-input-vat"
                value={dataDetail.taxType}
                options={dataList.taxList}
                getOptionLabel={(option) => option.TaxName}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    taxType: v,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        MRP: v?.SalesTax
                          ? (v.SalesTax / 100) *
                              dataDetail.multiUnit[0].SalesPrice +
                            Number(dataDetail.multiUnit[0].SalesPrice)
                          : dataDetail.multiUnit[0].SalesPrice,
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
                renderInput={(params) => (
                  <VBInputField label="Product Group" type="text" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Exicse Tax"
                type="text"
                //   value={dataDetail.creditPeriod}
                //   onChange={(e) => {
                //     setDataDetail({
                //       ...dataDetail,
                //       creditPeriod: e.target.value,
                //     });
                //   }}
              />
            </Grid>

            <Grid item xs={4}>
              <ViknAutoComplete
                disablePortal
                label="Unit"
                id="custom-input-demo"
                value={dataDetail.unit}
                options={dataList.unitList}
                getOptionLabel={(option) => option.UnitName}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    unit: v,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        UnitName: v ? v.UnitName : "",
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
                renderInput={(params) => (
                  <VBInputField label="Product Group" type="text" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <VBInputField
                label="Barcode"
                type="text"
                value={dataDetail.multiUnit[0].Barcode}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        Barcode: e.target.value,
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack direction="row" spacing={2} sx={{ mb: 2, alignItems: "center",}}>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              setOpenBarModal(true);
            }}
          >
            + Multi Barcode
          </Button>
          {/* <Box sx={{ }}> */}
          <Stack direction="row" spacing={2} sx={{ mb: 2, alignItems: "center", width: "calc(100vw - 296px)", overflowX: "auto" }}>
          {dataDetail.BarcodeDetails.map((obj, ind) => (
            <Card
              variant="outlined"
              sx={{
                px: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1,
                backgroundColor: "#F9F9F9",
                borderRadius: "6px",
              }}
            >
              <Typography color="secondary" sx={{ fontSize: "14px" }} >
                Barcode: <span style={{ color: "black" }}>{obj.barcode}</span>
              </Typography>
              <Divider orientation="horizontal" variant="fullWidth" />
              <Typography color="secondary" sx={{ fontSize: "14px" }}>
                Unit: <span style={{ color: "black" }}>{obj.unit}</span>
              </Typography>
              <IconButton color="secondary" size="small" onClick={() => {
                setDataDetail({
                  ...dataDetail,
                  BarcodeDetails: dataDetail.BarcodeDetails.filter((e, i) => i !== ind) 
                })
              }}>
                <ClearIcon />
              </IconButton>
            </Card>
          ))}
          </Stack>
          {/* </Box> */}
        </Stack>
      </Box>

      <Collapse in={!dataDetail.is_Service}>
        <Divider variant="fullWidth" sx={{ mb: 5 }} />
        <Box sx={{ px: 4 }}>
          <Typography
            sx={{
              color: "#001746",
              fontSize: "16px",
              fontWeight: "500",
              mb: 2,
            }}
          >
            Stock Details
          </Typography>
          <Box sx={{ flexGrow: 1, width: "75%", mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <VBInputField
                  label="Stock Minimum"
                  type="text"
                  value={dataDetail.StockMinimum}
                  onChange={(e) => {
                    setDataDetail({
                      ...dataDetail,
                      StockMinimum: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <VBInputField
                  label="Stock Maximum"
                  type="text"
                  value={dataDetail.StockMaximum}
                  onChange={(e) => {
                    setDataDetail({
                      ...dataDetail,
                      StockMaximum: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <VBInputField
                  label="Stock Order"
                  type="text"
                  value={dataDetail.StockOrder}
                  onChange={(e) => {
                    setDataDetail({
                      ...dataDetail,
                      StockOrder: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <ViknAutoComplete
                  disablePortal
                  label="Inventory Type"
                  id="custom-input-demo"
                  value={dataDetail.productGroup}
                  options={dataList.priductGroupList}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, v) => {
                    setDataDetail({
                      ...dataDetail,
                      productGroup: v,
                    });
                  }}
                  renderInput={(params) => (
                    <VBInputField type="text" {...params} />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={4}
                component={Collapse}
                in={dataDetail.is_Weighing}
              >
                <ViknAutoComplete
                  disablePortal
                  label="Weighing Calc Type"
                  id="custom-input-demo"
                  value={dataDetail.WeighingCalcType}
                  options={dataList.WeighingCalcTypes}
                  onChange={(e, v) => {
                    setDataDetail({
                      ...dataDetail,
                      WeighingCalcType: v,
                    });
                  }}
                  renderInput={(params) => (
                    <VBInputField type="text" {...params} />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={4}
                component={Collapse}
                in={dataDetail.is_Weighing}
              >
                <VBInputField
                  label="PLU No"
                  type="text"
                  value={dataDetail.PLUNo}
                  onChange={(e) => {
                    setDataDetail({
                      ...dataDetail,
                      PLUNo: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Collapse>

      <Box sx={{ flexGrow: 1, p: 4, backgroundColor: "#FAFAFA" }}>
        <Grid container rowSpacing={2} sx={{ width: "85%" }}>
          <Grid
            item
            xs={2.4}
            orientation="horizontal"
            component={Collapse}
            in={!dataDetail.is_Service}
            mountOnEnter
            unmountOnExit
          >
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Weighing Scale"
              control={
                <Checkbox
                  checked={dataDetail.is_Weighing}
                  onChange={() => {
                    setDataDetail({
                      ...dataDetail,
                      is_Weighing: !dataDetail.is_Weighing,
                    });
                  }}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Raw Material"
              control={
                <Checkbox
                  checked={dataDetail.is_RawMaterial}
                  onChange={() => {
                    setDataDetail({
                      ...dataDetail,
                      is_RawMaterial: !dataDetail.is_RawMaterial,
                    });
                  }}
                  size="small"
                  disabled={dataDetail.is_BOM}
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Finished Product"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  disabled={dataDetail.is_BOM}
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Sales"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Purchase"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  disabled={dataDetail.is_BOM}
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Favourite"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Active"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="Inclusive"
              control={
                <Checkbox
                  // checked={en.IsShipping}
                  // onChange={(e) => {}}
                  size="small"
                  //  disabled={radio.disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={2.4}>
            <FormControlLabel
              sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              label="BOM"
              control={
                <Checkbox
                  checked={dataDetail.is_BOM}
                  onChange={() => {
                    setDataDetail({
                      ...dataDetail,
                      is_BOM: !dataDetail.is_BOM,
                    });
                  }}
                  size="small"
                />
              }
            />
          </Grid>
        </Grid>
      </Box>

      <AddBarcode
        dataDetail={dataDetail}
        setDataDetail={setDataDetail}
        open={openBarModal}
        handleClose={() => {
          setOpenBarModal(false);
        }}
      />
    </>
  );
}

export default ProductDetail;

// ||-----------------------------------------------------------------||
// ===================== +++ Add Barcode Modal +++ =====================
// ||-----------------------------------------------------------------||

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  // p: 4,
  boxShadow: 24,
  borderRadius: "15px",
};

const AddBarcode = function ({ open, handleClose, dataDetail, setDataDetail }) {
  const [barData, setBarData] = useState({
    barcode: "",
    unit: "",
  });

  const handleCloseIcon = function () {
    handleClose();
  };

  const handleSave = function () {
    setDataDetail({
      ...dataDetail,
      BarcodeDetails: [...dataDetail.BarcodeDetails, barData],
    });
    setBarData({
      barcode: "",
      unit: "",
    })
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            py: 1,
          }}
        >
          <Typography>Add Multibar</Typography>

          <IconButton color="secondary" onClick={handleCloseIcon}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <Stack
          direction="column"
          spacing={2}
          sx={{
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <VBInputField
            label="Barcode"
            type="text"
            value={barData.barcode}
            onChange={(e) => {
              setBarData({
                ...barData,
                barcode: e.target.value,
              });
            }}
          />

          <VBInputField
            label="Unit"
            type="number"
            value={barData.unit}
            onChange={(e) => {
              setBarData({
                ...barData,
                unit: e.target.value,
              });
            }}
          />

          <Button
            color="secondary"
            sx={{ color: "white", width: "100%", boxShadow: "none" }}
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
