import * as React from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import {
  Typography,
  InputAdornment,
  Box,
  Modal,
  Fade,
  TableBody,
  TableRow,
  List,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";

import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"; // Import a date icon or any icon you prefer
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "./ReportTable/ReportTable";
import { CalendarToday } from "@mui/icons-material";
import { values } from "lodash";

export function InvoiceLedgerSearch({
  name,
  label,
  optionLabel,
  optionLabel2,
  OptionList,
  Value,
  OnChange,
  OnInputChange,
  openBillingAddressModal,
  BillingAddress,
  is_customer,
}) {
  return (
    <Stack spacing={2} sx={{ width: 300, borderRadius: "10px" }}>
      <Typography
        id="modal-modal-description"
        sx={{
          fontSize: "13px",
          margin: 0,
          textAlign: "left",
          fontWeight: "600",
          color: "#001746",
        }}
      >
        {" "}
        {label} :{" "}
      </Typography>

      <Autocomplete
        autoHighlight
        id="size-small-standard"
        size="small"
        options={OptionList ? OptionList : []}
        name={name}
        getOptionLabel={(option) => option[optionLabel]}
        value={Value}
        style={{ marginTop: "1px" }}
        onChange={(e, newValue) => {
          OnChange(e, newValue, name); // Pass the newValue and name to the handler
        }}
        onInputChange={(e, newInputValue, type) => {
          if (type === "input") {
            OnInputChange(newInputValue, name); // Pass the newInputValue and name to the handler
          }
        }}
        PaperComponent={(props) => (
          <Paper {...props} style={{ height: "100%", overflow: "hidden" }}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    padding: "8px",
                    width: "30%",
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  LedgerCode
                </th>
                <th
                  style={{
                    textAlign: "left",
                    padding: "8px",
                    width: "70%",
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  Name
                </th>
              </tr>
            </table>
            <List>{props.children}</List>
          </Paper>
        )}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    padding: "8px",
                    width: "30%",
                    fontSize: "12px",
                  }}
                >
                  {option.LedgerCode}
                </td>
                <td
                  style={{
                    textAlign: "left",
                    padding: "8px",
                    width: "70%".width,
                    fontSize: "12px",
                  }}
                >
                  {option.LedgerName}
                </td>
              </tr>
            </table>
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label=""
            placeholder="Select an option"
          />
        )}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0",
        }}
        className="truncated-container"
      >
        <Typography
          id="modal-modal-description"
          sx={{ fontSize: "13px", margin: 0 }}
        >
          VAT No:{" "}
        </Typography>
        <Typography
          id="modal-modal-description"
          className="truncated-text"
          sx={{ fontSize: "13px", margin: 0 }}
        >
          {Value?.TaxNo}
        </Typography>
        <div className="full-text">{Value?.TaxNo}</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0",
        }}
        className="truncated-container"
      >
        <Typography
          id="modal-modal-description"
          sx={{ fontSize: "13px", margin: 0 }}
        >
          To:{" "}
        </Typography>
        <Typography
          id="modal-modal-description"
          className="truncated-text "
          sx={{ fontSize: "13px", margin: 0 }}
        >
          {BillingAddress ? BillingAddress.Attention : ""}
        </Typography>
        <div className="full-text"></div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0",
        }}
        className="truncated-container"
      >
        <Typography
          id="modal-modal-description"
          sx={{ fontSize: "13px", margin: 0 }}
        >
          Address:{" "}
        </Typography>
        <Typography
          id="modal-modal-description"
          className="truncated-text "
          sx={{ fontSize: "13px", margin: 0 }}
        >
          {BillingAddress
            ? BillingAddress.Address1 +
              BillingAddress.Address2 +
              BillingAddress.City +
              BillingAddress.District +
              BillingAddress.PostalCode
            : Value?.BillingAddress}
        </Typography>
        <div className="full-text">
          {BillingAddress
            ? BillingAddress.Address1 +
              BillingAddress.Address2 +
              BillingAddress.City +
              BillingAddress.District +
              BillingAddress.PostalCode
            : Value?.BillingAddress}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0",
        }}
        className="truncated-container"
      >
        <Typography
          id="modal-modal-description"
          sx={{ fontSize: "13px", margin: 0 }}
        >
          Mobile:{" "}
        </Typography>
        <Typography
          id="modal-modal-description"
          className="truncated-text "
          sx={{ fontSize: "13px", margin: 0 }}
        >
          {BillingAddress ? BillingAddress.Mobile : Value?.Mobile}
        </Typography>
        <div className="full-text">{Value?.Mobile}</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0",
        }}
        className="truncated-container"
      >
        <Typography
          id="modal-modal-description"
          sx={{ fontSize: "13px", margin: 0 }}
        >
          Route:{" "}
        </Typography>
        <Typography
          id="modal-modal-description"
          className="truncated-text "
          sx={{ fontSize: "13px", margin: 0 }}
        >
          {Value?.RouteName}
        </Typography>
        <div className="full-text">{Value?.RouteName}</div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0",
        }}
        className="truncated-container"
      >
        <Typography
          id="modal-modal-description"
          sx={{ fontSize: "13px", margin: 0 }}
        >
          Balance:{" "}
        </Typography>
        <Typography
          id="modal-modal-description"
          className="truncated-text "
          sx={{ fontSize: "13px", margin: 0 }}
        >
          {Value?.Balance != null
            ? Number(Value.Balance).toFixed(2)
            : Number(Value?.CustomerLedgerBalance).toFixed(2)}
        </Typography>
        <div className="full-text">
          {Value?.Balance ?? Value?.CustomerLedgerBalance}
        </div>
      </div>

      {is_customer ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0",
          }}
          className="truncated-container"
        >
          <Typography
            id="modal-modal-description"
            sx={{
              fontSize: "13px",
              margin: 0,
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() => openBillingAddressModal()}
          >
            {"Add Billing Address"}
          </Typography>
          <Typography
            id="modal-modal-description"
            className="truncated-text "
            sx={{ fontSize: "13px", margin: 0 }}
          ></Typography>
        </div>
      ) : null}
    </Stack>
  );
}

export function SelectShippingAddress(props) {
  const {
    state,
    setState,
    Value,
    open,
    setOpen,
    Create_ShippingAddress,
    setShippingAddress,
    ShippingAddress,
  } = props;

  const fetchData = async () => {
    let res = await Create_ShippingAddress(ShippingAddress, "list");
    if (res) {
      setShippingAddress({ ...ShippingAddress, ShippingAddressList: res });
    }
  };

  const handleOpen = async () => {
    await fetchData();
    setOpen(true);
  };

  const handleEdit = () => {
    setOpen(true);
  };

  const handleRemove = () => {
    setState({ ...state, ShippingAddress: null });
  };

  return (
    <div
      style={{
        border: "1px solid #EFEFEF",
        borderRadius: "10px",
        maxWidth: "200px",
        minHeight: "160px",
      }}
    >
      <h4
        style={{
          fontSize: "14px",
          fontWeight: "500",
          color: "#001746",
          margin: "8px",
        }}
      >
        Shipping Address
      </h4>

      {Value ? (
        <>
          <div
            className="truncated-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0",
              padding: "0px 5px",
            }}
          >
            <p style={{ fontSize: "13px", margin: "0" }}>To:</p>
            <p
              id="modal-modal-description"
              className="truncated-text "
              style={{ fontSize: "13px", margin: "0" }}
            >
              {Value.Attention}
            </p>
            <div className="full-text">{Value.Attention}</div>
          </div>

          <div
            className="truncated-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0",
              padding: "0px 5px",
            }}
          >
            <p style={{ fontSize: "13px", margin: "0" }}>Address:</p>
            <p
              id="modal-modal-description"
              className="truncated-text "
              style={{ fontSize: "13px", margin: "0" }}
            >
              {Value.Address1},{Value.Address2},{Value.City},{Value.District},
              {Value.PostalCode}
            </p>
            <div className="full-text">
              {Value.Address1},{Value.Address2},{Value.City},{Value.District},
              {Value.PostalCode}
            </div>
          </div>

          <div
            className="truncated-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0",
              padding: "0px 5px",
            }}
          >
            <p style={{ fontSize: "13px", margin: "0" }}>Mobile:</p>
            <p style={{ fontSize: "13px", margin: "0" }}>{Value.Mobile}</p>
            <div className="full-text">{Value.Mobile}</div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0",
              padding: "0px 5px",
            }}
          >
            {/* <Button style={{height:"20px"}} onClick={handleRemove} variant="outlined">Remove</Button> */}
            <CloseIcon
              onClick={handleRemove}
              style={{
                padding: "0px",
                margin: "0px",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            />
            {/* coming soon feature */}
            {/* <Button style={{height:"20px"}} onClick={handleEdit} variant="outlined">Edit</Button> */}
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0",
              padding: "25px 5px",
            }}
          >
            {/* <Button onClick={handleOpen} variant="outlined">Select</Button> */}
            <span
              onClick={handleOpen}
              style={{
                // background: "#57b697",
                background: "#0A9EF3",
                width: "40px",
                height: "40px",
                borderRadius: "50px",
                fontSize: "39px",
                fontWeight: "lighter",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "auto",
                marginBottom: "5px",
                cursor: "pointer",
                color: "#292929",
              }}
            >
              +
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export function InvoiceAutoComplete({
  name,
  label,
  optionLabel,
  List,
  Value,
  OnChange,
  OnInputChange,
  placeholder,
}) {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={List}
      placeholder={placeholder}
      getOptionLabel={(option) => option[optionLabel]}
      popupIcon={<KeyboardArrowDownIcon />}
      sx={{
        minWidth: 100,
        width: 200,
        height: 35,
        "& .MuiOutlinedInput-root": {
          padding: "0 14px", // Custom padding for the input field
          borderRadius: "5px", // Optional: rounded corners
          "& fieldset": {},
          "&:hover fieldset": {
            borderColor: "#0A9EF3", // Change border color to blue on focus
          },
          "&.Mui-focused fieldset": {
            borderColor: "#0A9EF3", // Change border color to blue on focus
          },
        },
        backgroundColor: "#efefef",
        padding: "2px 1px",
        borderRadius: "8px",
      }}
      value={Value}
      onChange={(e, newValue) => {
        OnChange(e, newValue, name);
      }}
      onInputChange={(e, newInputValue, type) => {
        if (type === "input") {
          OnInputChange(newInputValue, name);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={""}
          InputProps={{
            ...params.InputProps,
            sx: {
              height: 35, // Set the height of the input field
              padding: "0 14px", // Adjust padding
              display: "flex",
              alignItems: "center",
              "& .MuiInputBase-input": {
                height: "100%",
                padding: "0", // Adjust padding for the input text
                display: "flex",
                alignItems: "center",
                lineHeight: "normal", // Reset line-height
              },
            },
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              height: "13px", // Adjusted height for the input text
              lineHeight: "38px", // Adjust line-height to center text
              padding: "0 1px", // Adjust padding inside the input
              fontSize: "small",
            },
          }}
        />
      )}
    />
  );
}

export function InvoiceTextBox({
  type,
  name,
  label,
  Value,
  OnChange,
  placeholder,
}) {
  const inputRef = React.useRef(null);
  const { PriceDecimalPoint } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };
  return (
    <TextField
      type={type ? type : null}
      value={Value}
      name={name}
      onChange={OnChange}
      placeholder={placeholder}
      label=""
      onClick={handleClick}
      inputRef={inputRef}
      InputProps={{
        sx: {
          width: 200,
          height: 35, // Set the height of the input field
          padding: "0px 20px", // Adjust padding
          display: "flex",
          alignItems: "center",
          backgroundColor: "#efefef",
          "& .MuiInputBase-input": {
            height: "100%",
            padding: "0", // Adjust padding for the input text
            display: "flex",
            alignItems: "center",
            lineHeight: "normal", // Reset line-height
          },
          // "& .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "blue", // Default border color
          // },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0A9EF3", // Change border color to blue on focus
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0A9EF3", // Change border color to blue on hover
          },
          "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0A9EF3", // Ensure active state is blue
          },
        },
      }}
      inputProps={{
        style: {
          height: "13px", // Adjusted height for the input text
          lineHeight: "38px", // Adjust line-height to center text
          padding: "0 1px", // Adjust padding inside the input
          fontSize: "small",
        },
      }}
    />
  );
}

export function InvoiceDateBox({ name, label, Value, OnChange, width }) {
  return (
    <TextField
      name={name}
      value={Value}
      onChange={(e) => {
        OnChange(e);
      }}
      type="date"
      // label={label}
      InputProps={{
        sx: {
          width: width ? width : 170,
          height: 35, // Set the height of the input field
          padding: "0 14px", // Adjust padding
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#efefef",
          alignItems: "center",
          borderRadius: "8px",
          "& .MuiInputBase-input": {
            height: "100%",
            padding: "0", // Adjust padding for the input text
            display: "flex",
            alignItems: "center",
            lineHeight: "normal", // Reset line-height
            color: "black", // Set text color to black
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0A9EF3", // Change border color to blue on focus
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0A9EF3", // Change border color to blue on hover
          },
          "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0A9EF3", // Ensure active state is blue
          },
        },
      }}
      inputProps={{
        style: {
          height: "13px", // Adjusted height for the input text
          lineHeight: "38px", // Adjust line-height to center text
          padding: "0 1px", // Adjust padding inside the input
          fontSize: "small",
          display: "flex",
          justifyContent: "center",
          color: "black", // Set text color to black
        },
      }}
    />
  );
}

export function InvoiceVoucherNoTextBox({
  name,
  label,
  Value,
  OnChange,
  openCustomVoucher,
  setCustomVoucher,
  is_edit,
}) {
  return (
    <TextField
      name={name}
      value={Value}
      OnChange={OnChange}
      label=""
      InputProps={{
        sx: {
          width: 200,
          height: 35, // Set the height of the input field
          padding: "0px 17px", // Adjust padding
          display: "flex",
          alignItems: "center",
          backgroundColor: "#efefef",
          borderRadius: "5px",
          border: "none",
          "& .MuiInputBase-input": {
            height: "100%",
            padding: "0", // Adjust padding for the input text
            display: "flex",
            alignItems: "center",
            lineHeight: "normal", // Reset line-height
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0A9EF3", // Change border color to blue on focus
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0A9EF3", // Change border color to blue on hover
          },
          "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0A9EF3", // Ensure active state is blue
          },
        },
        endAdornment: (
          <InputAdornment position="end">
            {!is_edit && (
              <SettingsIcon
                sx={{ cursor: "pointer", color: "#0A9EF3" }}
                onClick={() => setCustomVoucher(true)}
              />
            )}
          </InputAdornment>
        ),
      }}
      inputProps={{
        style: {
          height: "13px", // Adjusted height for the input text
          lineHeight: "38px", // Adjust line-height to center text
          padding: "0 1px", // Adjust padding inside the input
          fontSize: "small",
        },
      }}
    />
  );
}

export function InvoiceNoTextBox({
  name,
  label,
  Value,
  OnChange,
  PlaceHolder,
}) {
  return (
    <TextField
      name={name}
      value={Value}
      OnChange={OnChange}
      label=""
      placeholder={PlaceHolder}
      InputProps={{
        sx: {
          width: 200,
          height: 30, // Set the height of the input field
          padding: "22px 14px", // Adjust padding
          border: "none",
          borderRadius: "10px",

          "& .MuiInputBase-input": {
            height: "100%",
            padding: "0", // Adjust padding for the input text
            display: "flex",
            alignItems: "center",
            lineHeight: "normal", // Reset line-height
          },
        },
        endAdornment: (
          <InputAdornment position="end">
            <SettingsIcon style={{ cursor: "pointer" }} />
          </InputAdornment>
        ),
      }}
      inputProps={{
        style: {
          height: "13px", // Adjusted height for the input text
          lineHeight: "38px", // Adjust line-height to center text
          padding: "0 1px", // Adjust padding inside the input
          fontSize: "small",
        },
      }}
    />
  );
}
export function InvoiceBarCodeTextBox({ onEnter, placeholder }) {
  const inputRef = React.useRef(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnter(event.target.value);
      event.target.value = "";
    }
  };

  return (
    <TextField
      onKeyDown={handleKeyDown}
      label=""
      onClick={handleClick}
      inputRef={inputRef}
      placeholder={placeholder}
      InputProps={{
        sx: {
          width: 200,
          height: 30, // Set the height of the input field
          padding: "20px", // Adjust padding
          display: "flex",
          alignItems: "center",
          backgroundColor: "#efefef",
          "& .MuiInputBase-input": {
            height: "100%",
            padding: "0", // Adjust padding for the input text
            display: "flex",
            alignItems: "center",
            lineHeight: "normal", // Reset line-height
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove border for outlined variant
          },
        },
      }}
      inputProps={{
        style: {
          height: "13px", // Adjusted height for the input text
          lineHeight: "38px", // Adjust line-height to center text
          padding: "0 1px", // Adjust padding inside the input
          fontSize: "small",
        },
      }}
    />
  );
}

export function MenuOptions(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { OptionList, OptionOnClick, uniq_id, disable } = props;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, name) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    OptionOnClick(event, name, uniq_id);

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    // <Stack direction="row" spacing={2}>
      <div>
        <MoreVertIcon
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {OptionList.map((option) => (
                      <MenuItem
                        disabled={ option?.name === 'Delete' && option?.is_disabled && disable ? true  :   disable && !props.Taxcategory && option.name === "Edit"}
                        name={option.name}
                        onClick={(e) => {
                          handleClose(e, option.name);
                        }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    // </Stack>
  );
}

export function CustomVoucher({
  open,
  setCustomVoucher,
  state,
  handleChange,
  CustomVoucherSet,
}) {
  const handleClose = () => setCustomVoucher(false);

  const inputStyle = {
    width: 90,
    height: 30,
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    "& .MuiInputBase-input": {
      // height: "100%",
      padding: "0",
      display: "flex",
      alignItems: "center",
      lineHeight: "normal",
    },
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{ padding: "0px", margin: "0px" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "281px",
          right: "-224px",
          transform: "translate(-50%, -50%)",
          width: 550,
          bgcolor: "background.paper",
          boxShadow: 24,
          margin: 0,
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            fontWeight: "500",
            margin: "0px",
            marginLeft: "10px",
          }}
        >
          Set Custom Format
        </p>
        <div
          style={{ display: "flex", paddingInlineStart: 10, padding: "10px" }}
        >
          <div style={{ display: "block" }}>
            <Label>Prefix:</Label>
            <TextField
              sx={{ paddingInlineEnd: 2 }}
              InputProps={{ sx: inputStyle }}
              disabled={true}
              value={state.PreFix}
            />
          </div>
          <div style={{ display: "block" }}>
            <Label>Seperator:</Label>
            <TextField
              sx={{ paddingInlineEnd: 2 }}
              InputProps={{ sx: inputStyle }}
              disabled={true}
              value={state.Seperator}
            />
          </div>
          <div style={{ display: "block" }}>
            <Label>Digits:</Label>
            <TextField
              sx={{ paddingInlineEnd: 2 }}
              InputProps={{ sx: inputStyle }}
              autoFocus
              value={state.InvoiceNo}
              name="InvoiceNo"
              type="number"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div style={{ display: "block" }}>
            <Label>Separator:</Label>
            <TextField
              sx={{ paddingInlineEnd: 2 }}
              InputProps={{ sx: inputStyle }}
              disabled={true}
              value={state.SuffixSeparator}
            />
          </div>
          <div style={{ display: "block" }}>
            <Label>Suffix:</Label>
            <TextField
              InputProps={{ sx: inputStyle }}
              disabled={true}
              value={state.Suffix}
            />
          </div>
        </div>
        <p style={{ fontSize: "11px", color: "#6d7676" }}>
          Hint : You can only change the invoice number; for everything else,
          you need to go to settings
        </p>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <DoneButton onClick={CustomVoucherSet}>Done</DoneButton>
        </div>
      </Box>
    </Modal>
  );
}

export function ProductPaper(props) {
  let data = props.data;
  data = data.filter((i) => i.permission === true);

  console.log(data, "data");
  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
      }}
    >
      <tr>
        {data.map((i) => (
          <th
            style={{
              textAlign: i.numeric === true ? "right" : "left",
              padding: "8px",
              width: i.width,
              fontSize: "13px",
              fontWeight: 500,
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            {i.name}
          </th>
        ))}
      </tr>
    </table>
  );
}

export function ProductRenderComponent(props) {
  let data = props.data;
  data = data.filter((i) => i.permission === true);

  let option = props.option;
  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
      }}
    >
      <tr>
        {data.map((i) => (
          <td
            style={{
              textAlign: i.numeric === true ? "right" : "left",
              padding: "8px",
              width: i.width,
              fontSize: "12px",
            }}
          >
            {i.numeric === true
              ? Number(option[i.value]).toFixed(2)
              : option[i.value]}
          </td>
        ))}
      </tr>
    </table>
  );
}

const Label = styled.p`
  margin: 0;
  font-size: 13px;
`;
const DoneButton = styled.button`
  width: 100px;
  height: 30px;
  background-color: #0a9ef3;
  color: white;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 550;
`;
